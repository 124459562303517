.userDash{
    // margin-top: 6rem;

    .userDash-container{
        width: 90vw;
        margin: 0 auto;

        .userDash-header{
            margin-bottom: 3rem;
            h1{
              border-left: 2px solid #bba58f;
              padding-left: .5rem;
              letter-spacing: 1px;
              margin-bottom: .9rem;
              font-size: 1em;
            }

            .userNameGreeting{
                display: flex;
                flex-direction: row;
                align-items: center;
                text-wrap: wrap;
                gap: .5rem;

                p{
                    font-size: larger;
                }
            }
        }

        .dash-links{
            display: flex;
            gap: 1rem;
            margin-bottom: 5rem;

            a{
                width: 30rem;
                color: #333;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                text-align: center;
                align-items: center;
                padding: 2rem;
                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

              

                &:nth-child(1){
                    background: rgba(0, 0, 0, 0.5) url('../../stock/storeback.jpg') no-repeat;
                    background-position: center;
                    background-size: 150%;
                    background-blend-mode: overlay;
                    position: relative;
                    overflow: hidden;
                    
                    transition: background-size .3s ease-in-out;

                    &:hover{
                       background-size: 170%;
                    }
                }

                &:nth-child(2){
                    background:rgba(0, 0, 0, 0.5) url('../../stock/masterback.jpg') no-repeat;
                    background-position: center;
                    background-size: 150%;
                    background-blend-mode: overlay;
                    position: relative;
                    overflow: hidden;
                    transition: background-size .3s ease-in-out;

                    &:hover{
                       background-size: 170%;
                    }
                }

                &:nth-child(3){
                    background:rgba(0, 0, 0, 0.5) url('../../stock/accback.jpeg') no-repeat;
                    background-position: center;
                    background-size: 130%;
                    background-blend-mode: overlay;
                    position: relative;
                    overflow: hidden;
                    transition: background-size .3s ease-in-out;

                    &:hover{
                       background-size: 150%;
                    }
                }

                .dashLinks-icon{
                    font-size: 50px;
                    background: white;
                    border-radius: 50%;
                    padding: 1.5rem;
                }

                h1{
                    color: white;
                }

                p{
                    font-weight: 500;
                    line-height: 1.5;
                    color: white;

                }
            }

    
        }
    }
    
.page{
    padding-top: 6.5rem;
  }
    
}
@media screen and (max-width: 1080px) {
.userDash{

    .userDash-container{

        .dash-links{
            flex-wrap: wrap;
            justify-content: center;

            a{
                width: 20rem;
            }
        }
    }
}
}

