.reset-page{
    width: 100%;
    
    .reset-container{
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        margin-top: 5rem;

        .reset{
            margin-block: 6rem;
            padding: 1.6rem;
            border-radius: 1.6rem;
            // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            // border: 1px solid hsl(0, 0%, 93%);
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 60%;

            .error{
                text-align: center;
                font-size: .9em;
                color: red;
                font-weight: 500;
            }

                .success{
                text-align: center;
                font-size: .9em;
                color: #28A745;
                font-weight: 500;
            }

            .head{
                color: #888;
                font-weight: 500;
                margin-bottom: .7rem;
                font-size: 14px;
            }

            .email{
                display: flex;
                align-items: center;
                border: 1px solid hsl(0, 0%, 93%);
                gap: 1rem;
                margin-bottom: 1.5rem;
                border-radius: 3px;
                
                transition: .3s ease;

                &:hover{
                    transform: scale(.98);
                }
                input{
                    padding-block: .8rem;
                    width: 100%;
                    border: none;
                    outline:none;
                }

                .icon{
                  margin-left: 1rem;
                  font-size: 22.5px;
                  color: #888;
                }
            }
            
            button{
                background: black;
                color: white;
                padding: .9rem;
                border-radius: 10px;
                border: none;
                cursor: pointer;
                transition: .3s ease-in-out;
                font-weight: bold;
                letter-spacing: 1px;
                width: 100%;

                &:hover{
                    transform: scale(.95)
                }

                .login-spinner{
                    animation: spin 2s linear infinite;
                }

                @keyframes spin {
                    from {
                      transform: rotate(0deg);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }
            }

            .remember{
                text-align: center;
                font-size: .9em;
                margin-block: .5rem;
                font-weight: 500;
                color: #252525;
            }
        }
    }
}

@media screen and (max-width: 860px) {
.reset-page{

    .reset-container{
        width: 98%;

        .reset{
        width: 100%;
        // border: none;
        // box-shadow: none;

        .txt{
            padding-bottom: .5rem;
        }
        .txt, .head{
            font-size: .9em;
        }
        }
    }
}
}