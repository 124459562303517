.login-page{
    width: 100%;

    .login-page-container{
        width: 95%;
        display: grid;
        grid-template-columns: 46% 50%;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 6rem;

        .login-left{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 5rem 3rem;
            position: relative;

            h1{
                .wave-icon{
                    color: #FED246;
                    font-size: 25px;
                }
            }

            .google-login{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                padding: .7rem 1.5rem;
                gap: .8rem;
                border: 1px solid #888;
                background: none;
                margin-top: 1rem;
                cursor: pointer;
                transition: .2s ease-in-out;

                &:hover{
                    transform:scale(.9)
                }


                .google-icon{
                    font-size:30px;
                }

                h3{
                    font-weight: 400;
                }
            }

            .or{
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: .7rem;
                margin-top: 1.5rem;

                span{
                    width: 9.5rem;
                    height: 1px;
                    background: #888;
                }

                h4{
                    font-weight: 400;
                    text-align: center;
                }
            }

            .login-form{
                display: flex;
                flex-direction: column;
                // gap: 2rem;

                    .emailnpassword{
                        display: inherit;
                        flex-direction: inherit;
                        gap: .7rem;


                        h2{
                            font-weight: 500;
                        }

                        input{
                            padding: 1rem;
                            border-radius: 10px;
                            border: 1px solid #888;
                            outline: none;
                            transition: .2s ease-in-out;

                            &:focus{
                                transform: scale(.97)
                            }

                        }

                        .email-input{
                            margin-bottom: 1.5rem;
                        }

                    }

                    .login-btn{
                        background: black;
                        color: white;
                        padding: 1rem;
                        border-radius: 10px;
                        border: none;
                        cursor: pointer;
                        transition: .3s ease-in-out;
                        margin-top: 2rem;
                        font-weight: bold;
                        letter-spacing: 1px;

                        &:hover{
                            transform: scale(.95)
                        }

                        .login-spinner{
                            animation: spin 2s linear infinite;
                        }

                        @keyframes spin {
                            from {
                              transform: rotate(0deg);
                            }
                            to {
                              transform: rotate(360deg);
                            }
                          }
                    }

                    .passcheck{
                        margin-top: .6rem;
                        color: red;
                        text-align: center;
                    font-weight: 500;
                    }

                    .forgot-pass{
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        margin-top: .5rem;

                        a{
                            text-decoration: none;
                            font-weight: 500;
                            color: #666;
                        }
                    }

                    .sign-up-link{
                        text-align: center;
                        margin-top: 2rem; 

                        a{
                            text-decoration: none;
                            font-weight: 500;

                        }
                    }
            }

        }

        .login-right{
            background: url('../../stock/login.jpg')no-repeat;
            background-size: cover;
            background-position: center;
            background-blend-mode: overlay;
            position: relative;
            border-radius:20px;
            border-bottom-left-radius: 8rem;
        }
    }


}

@media screen and (max-width: 1080px) {
.login-page{
    width: 90vw;
    margin: 0 auto;
    font-size: .8em;


    .login-page-container{
        display: flex;
        justify-content: center;
        width: 90vw;
        position: relative;
    

        .login-left{
            padding: 0;
            width: 60%;
        
        }

        .login-right{
            display: none;
        }
    }
}
}

@media screen and (max-width: 720px) {
.login-page{
    .login-page-container{
        .login-left{
            width: 90%;
            margin-top: 1.5rem;
        }
    }
}
}