.masterclass-app{
    width: 100%;
    

    .body{
        width: 100%;
        // margin: 0 auto;
        margin-block: 7rem;
        display: flex;
        justify-content: center;
        overflow: hidden;

        background: rgba(0, 0, 0, 0.5) url('../../stock/masterclassh.jpg')no-repeat;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        position: relative;
        


        .content{
            width: 50%;
            text-align: center;
            line-height: 1.5;
            margin-block: 7rem;
            color: white;

            h1{
                font-size: 3em;
                // color: #333;
                margin-bottom: 10px;
            }

            p {
                font-size: 1.2em;
                color: #eee;
                margin-bottom: 30px;
              }

              
              .form-socials{
                margin-top: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1.5rem;
                font-size: 22.5px;
                color: #eee;

              .icon{
                cursor: pointer;
                transition: .3s ease-in-out;


                &:hover{
                    transform: scale(0.9);
                }
              }
            }
              
        }
    }
}

@media screen and (max-width: 720px) {
.masterclass-app{

    .body{
        margin-bottom: 0;

        .content{
            width: 70%;
        }
    }
}
}