.onboarding{

    .uploadProfilePicture{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        align-items: center;


        .progress{
        position: relative;
        display: flex;
        gap: 3rem;
        margin-bottom: 3rem;
        justify-content: center;


        .bar{
            position: absolute;
            top: 20%;
            width: 77%;
            height: 1px;
            background: hsl(0, 0%, 73%);

            left: 50%;
            transform: translateX(-50%);

        }

        span{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;

            .completed-icon{
                color: green;
            }

            h4{
                font-weight: 400;
                font-size: 15px;
            }

            p{
                padding-inline: 5px;
                padding-block: 1px;
                background: white;
                z-index: 2; 
            }

            .current-page{
                    border: 1px solid #888;
                    border-radius: 50%;
            }
        }
        }


        .upload-profilePicture-Container{
            display: inherit;
            flex-direction: inherit;
            justify-content: center;
            align-items: center;

            h2{
                font-weight: 400;
                margin: 2rem 0;
            }

            input{
                margin-bottom: 1rem;
                padding: .8em;
                border-radius: 5px;
                border: none;
                outline: none;
            }
    
            input[type="file"] {
                background-color: #f0f0f0;
                border: 1px solid #ccc; 
                padding: 5px; 
                font-size: 16px; 
                cursor: pointer; 
              }

              button{
                margin: 1rem 0;
                width: fit-content;
                background: black;
                color: white;
                border: none;
                padding: 10px 18px;
                border-radius: 8px;
                cursor: pointer;
                transition: .2s ease-in-out;

                &:hover{
                    transform: scale(.95);
                }
              }
        }
    }
}

@media screen and (max-width: 767px) {
    .onboarding{
    
        .uploadProfilePicture{
    
            .progress{
                width: 95vw;
                overflow-x: hidden;
    
                .bar{
                    width: 70%;
                }
    
                span{
    
                    h4{
                        font-size: 10px;
                        text-align: center;
                    }
                }
            }
        }
    }
    
    }