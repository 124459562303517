.onboarding{
    
    
    .uploadAddress{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        align-items: center;
        position: relative;


        .popup{
            position: fixed;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            z-index: 10000;
            bottom: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .popup-container{
                padding: 5rem;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2rem;
                background: white;
                line-height: 1.5;
                
                }

                .edit-container{
                    gap: 1rem;

                    .completed-icon{
                        font-size: 70px;}
                }
            

            .completed-icon{
                font-size: 45px;
                color: white;
                padding: 1rem;
                background: #4EDB42;
                border-radius: 50%;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }

            p{
                text-align: center;
                font-weight: 500;
            }

            button{
                width: fit-content;
                background: black;
                color: white;
                border: none;
                padding: 10px ;
                border-radius: 8px;
                cursor: pointer;
                transition: .2s ease-in-out;

                &:hover{
                    transform: scale(.95);
                }
            }
            
        }

            .upload-address-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: center;
                align-items: center;
                height: 80vh;
    
                h2{
                    font-weight: 400;
                    margin: 2rem 0;
                }

                form{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;
                    

                    input{
                        padding: 1rem;
                        border-radius: 8px;
                        outline: none;
                        border: none;
                        background: hsl(0, 0%, 93%);
                    }

                select{
                            width: 100%;
                            padding: 9px;
                            border: 1px solid #ccc;
                            border-radius: 5px;
                            font-size: 16px;
                            outline: none;
                            border: none;
                            background: hsl(0, 0%, 93%);

                            &:focus {
                              border-color: #007bff; /* Change border color when focused */
                            }

                 }
                 
                 .action-btn{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    grid-column: span 2; /* Span the button across both columns */
                    place-self: center;
                    gap: 20rem;

                    a{
                        color: #252525;
                        background: #eee;
                        padding: 10px 8px;
                        border-radius: 8px;
                        text-decoration: none;
                        cursor: pointer;
                        font-weight: 500;
                        font-size: 14px;
                    }
                    
                    button{
                       width: fit-content;
                       background: black;
                       color: white;
                       border: none;
                       padding: 10px 8px;
                       border-radius: 8px;
                       cursor: pointer;
                       font-weight: 500;
                       transition: .2s ease-in-out;
                    //    grid-column: span 2; /* Span the button across both columns */
                    //    place-self: center;
       
                       &:hover{
                           transform: scale(.95);
                       }
                    }
                }

                .edit-btn{
                    width: fit-content;
                    background: black;
                    color: white;
                    border: none;
                    padding: 10px 8px;
                    border-radius: 8px;
                    cursor: pointer;
                    font-weight: 500;
                    transition: .2s ease-in-out;
                    grid-column: span 2; /* Span the button across both columns */
                    place-self: center;
    
                    &:hover{
                        transform: scale(.95);
                    }
                 }

                          
                    
                }

                .number{
                    display: flex;
                    flex-direction: column;
                }

                .error-message {
                    color: red; /* Set text color to red */
                    margin-top: 1rem; /* Add some space above the error message */
                  }
            }

        
    }
}

@media screen and (max-width: 767px) {
    .onboarding{
    
        .uploadAddress{ 
            left: 0;
            right: 0;


            .popup{
              width: 100%;

            .popup-container{
                position: fixed;
                width: 50vw
            }

              p{
                width: 90vw;
                line-height: 1.5;
              }
            }
            
            .upload-address-container{
        
                form{
                   display: flex;
                   flex-direction: column;

                   .action-btn{
                    gap: 5rem;
                   }
                }
            
            }

        }
    }
   
    
    }

    