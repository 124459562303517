.myorders-page{
    width: 100%;

    .orders-container{
        width: 90vw;
        margin: 0 auto;

        h1{
            border-left: 2px solid #bba58f;
            padding-left: .5rem;
            letter-spacing: 1px;
            margin-bottom: 3rem;
            font-size: 1em;
        }

        .loading-message{
            margin-block: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem;
    
            .loading-card{
                width: 90%;
                display: flex;
                flex-direction: column;
                padding: 1rem;
                border-radius: 10px;
                gap: 1rem;
                box-shadow: rgba(80, 78, 78, 0.16) 0px 1px 4px;
    
    
                .loading-img{
                    height: 20px;
                    width: 100%;
                    border-radius: 10px;
                }
    
                .loading-text{
                    height: 20px;
                    width: 80%;
                    border-radius: 10px;
                }
    
                .loading-text-II{
                    height: 20px;
                    width: 60%;
                    border-radius: 10px;
                }
                .loading-text-III{
                    height: 20px;
                    width: 70%;
                    border-radius: 10px;
                }
    
                .loading-img,
                .loading-text,
                .loading-text-II,
                    .loading-text-III{
                    background-color: #ededed;
                    background: linear-gradient(
                        100deg,
                        rgba(255,255,255,0) 40%,
                        rgba(255,255,255, .5) 50%,
                        rgba(255,255,255,0) 60%
                    ) #ededed;
                    background-size: 200% 100%;
                    background-position-x: 100%;
                    animation: 1s loading ease-in-out infinite;
                }
                @keyframes loading {
                    to{
                         background-position-x: -20%;
                    }
                }
    
            }
        }

        .orders{
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            
            .order{
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                padding: 1.6rem;
                border-radius: 1.6rem;
                border: 1px solid hsl(0, 0%, 83%);
                display: flex;
                flex-direction: column;
                gap: 3rem;
                transition: .3s ease;

                &:hover{
                background: hsl(0, 0%, 95%);
                }


                .top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .bottom{
                    line-height: 1.8;
                    display: flex;
                    align-items: center;

                    .status{
                        width: 50%;
                        border-right: 1px solid hsl(0, 0%, 85%);
                        p{
                            font-weight: 500;
                            color: hsl(0, 0%, 25%);
                        }
                        
                        h5{
                            font-size: 15px;
                        }
                    }

                    .date{
                       margin-left: 1rem;

                       p{
                        font-weight: 500;
                        color: hsl(0, 0%, 25%);
                    }
                    }
                }

            }
        }

        .product-modal {
            position: fixed;
            top: 0;
            left: 0;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
            /* Apply display: none for closed state */
            display: none;
            z-index: 15;
            width: 100%;
            min-height: 100%;

            justify-content: center;
            align-items: center;
            flex-direction: column;
            overflow-x: hidden;
            background: #eee;

            .modal-container{
                background: white;
                padding: 2rem;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                height: 80vh;
                width: 80%;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                  }


                .modal-details{

                    .back{
                        border: none;
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        gap: .2rem;
                        background: none;
                        font-weight: 500;
                        cursor: pointer;
                        transition: .2s ease;
        
                        &:hover{
                        transform: scale(.98);
                        }
                     }

                     .route{
                        display: flex;
                        align-items: center;
                        margin-block: 1rem;
                        gap: .3rem;
                        font-weight: 500;

                        .icon{
                            color: #888;
                        }

                        .id{
                            color:#888;
                            width: 7rem;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .header{

                        .refId{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 1rem;

                            p{
                                font-size: 36px;
                                font-weight: 700;
                            }

                            button{
                                padding: 10px 15px;
                                border: none;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                gap: 7px;
                                cursor: pointer;
                                background: hsl(240, 100%, 50%);
                                color: white;
                                transition: .3s ease;
                                font-weight: 600;
                              
                            }
                            
                        }

                        .dates{
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            margin-bottom: 1rem;

                            .first{
                                border-right: 1px solid hsl(0, 0%, 73%);
                            }

                          

                            .p{
                                display: flex;
                                align-items: center;
                                padding-right: 1rem;
                                padding-block: .2rem;

                                h4{
                                    padding-left: .5rem;
                                }
                            }
                        }
                    }

                    .cart-details{
                        border-block: 1px solid hsl(0, 0%, 90%) ;
                        padding-block: 1.5rem;
                        margin-block: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;

                        .cart{
                            display: flex;
                           align-items: center;
                           justify-content: space-between;

                           .left{
                            display: flex;
                            align-items: center;
                            gap: 1rem;

                            span{
                                display: flex;
                                flex-direction: column;
                                gap: .8rem;

                                .name{
                                    font-weight: 600;
                                }
                                img{
                                    width: 100px;
                                    height: 100px;
                                    object-fit: cover;
                                    border-radius: 5px;
                                }
                            }
                        }

                        .right{
                            display: flex;
                            flex-direction: column;
                            gap: .8rem;
                            text-align: end;

                            .price{
                                font-weight: 600;
                            }
                        }

                        }
                    }

                    .bottom{
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        justify-content: space-between;
                        margin-top: 1.5rem;

                        .right{
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;

                            h2{
                                font-weight: 600;
                            }
                            h3{
                                color: #888;
                                font-size: 14px;
                            }

                            p{
                                font-weight: 500;
                            }

                            .second{
                                gap: .5rem;
                                color: #00B31D;
                                font-weight: 600;
                                display: flex;
                                align-items: center;

                                .icon{
                                    font-size: 20px;
                                }
                            }
                        }

                        .left{
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;

                            h2{
                                font-weight: 600;
                            }

                            p{
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

        .product-modal.open {
            display: flex; /* Show when open */
          }
    }

    .page{
        padding-top: 6.5rem;
      }
}

@media screen and (max-width: 1040px) {
.myorders-page{

    .orders-container{

        .product-modal{
            font-size: .8em;

            .modal-container{
                width: 90%;

                .modal-details .bottom{
                    margin-bottom: 10rem;
                }
            }
        }
    }
}
}

@media screen and (max-width: 595px) {
    .myorders-page{

        .orders-container{
    
            .product-modal{
                font-size: .8em;
    
                .modal-container{
                    height: 100vh;
                width: 90%;
                }

                .modal-container .modal-details{

                    .header .refId{
                        flex-direction: column;
                        gap: 1rem;
                        align-items: left;

                        p{
                            font-size: 25px;
                            text-align: center;
                        }
                        
                    }
    
                    .header{
    
                        .dates{
                           flex-direction: column;
       
                          .first{
                            border:none;
                           }
                       }
                    }
    
                    .bottom{
                        display: flex;
                        flex-direction: column;
                        gap: 2rem;
                        font-size: 1em;
                    }
    
                }
            }
        }
    }
}