.shop{
    width: 100%;

    .shop-landing{
        background: rgba(0, 0, 0, 0.5) url('../../stock/shoph.jpg')no-repeat;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        position: relative;
        
        
        h1{
            padding: 2rem 5rem;
            padding-top: 7rem;
            color: white;
            letter-spacing: 1px;
            
        }
    }
}

@media screen and (max-width: 1080px) {
.shop{
    
    .shop-landing{
        h1{
            padding: 2rem 2rem;
            padding-top: 7rem;
        }
    }
}
}