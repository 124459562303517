.theStore{
    width:100%;


    

    .uploaded-posts-container{
        width:95vw;
        margin: 0 auto;
        position: relative;

        .search-container{
            margin-bottom: 2rem;
            display: flex;
            flex-direction: row;
            gap: .7rem;
            justify-content: center;
            width: 100%;
            position: relative;

            span{
                display: flex;
                align-items: center;
                background-color: hsl(0, 0%, 97%);
                padding-inline: .7rem;
                border-radius: 30px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                width: 40%;
                border: 1px solid hsl(0, 0%, 93%);


                input{
                    border-radius: 30px;
                    padding: .7rem;
                    width: 100%;
                    outline: none;
                    border: none;
                    background-color: hsl(0, 0%, 97%);

                }
                .search-icon{
                    font-size: 18px;
                }
            }


            button{
                padding-inline: 1rem;
                border-radius: 50px;
                border: none;
                cursor: pointer;
                color: white;
                background: hsl(0, 0%, 10%);
                transition: .2s ease;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                font-weight: 500;


                &:hover{
                    transform: scale(.95);
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                }
            }
        }

        .categories-header{
            font-size: 15px;
            color: #888;
            text-align: center;
        }

        .categories-container{
            margin-bottom: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;


            .categories{
                margin-top: 1rem;
                display: flex;
                align-items: center;
                gap: 2rem;


                .category-name{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    gap: .7rem;
                    align-items: center;

                    button{
                        background: none;
                        border: none;
                        border-radius: 50%;
                        cursor: pointer;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                        border: 1px solid hsl(0, 0%, 83%);
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;

                        padding: 5px;
                    }
                    
                    img{
                        width: 35px;
                        transition: transform .3s linear;

                        &:hover{
                            transform: scale(1.05);
                        }
                        
                    }

                    p{
                        font-size: 13px;
                        font-weight: 600;
                    }

                }
            }
        }

        

        .loading-message{
            margin-bottom: 3rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem;
            justify-content: center;

            .loading-card{
                width: 12em;
                display: flex;
                flex-direction: column;
                padding: 1rem;
                border-radius: 10px;
                gap: 1rem;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;


                .loading-img{
                    height: 150px;
                    width: 100%;
                    border-radius: 10px;
                }

                .loading-text{
                    height: 25px;
                    width: 80%;
                    border-radius: 10px;
                }

                .loading-text-II{
                    height: 20px;
                    width: 60%;
                    border-radius: 10px;
                }

                .loading-img,
                .loading-text,
                .loading-text-II{
                    background-color: #ededed;
                    background: linear-gradient(
                        100deg,
                        rgba(255,255,255,0) 40%,
                        rgba(255,255,255, .5) 50%,
                        rgba(255,255,255,0) 60%
                    ) #ededed;
                    background-size: 200% 100%;
                    background-position-x: 100%;
                    animation: 1s loading ease-in-out infinite;
                }
                @keyframes loading {
                    to{
                         background-position-x: -20%;
                    }
                }

            }
        }

        .no-results.preview{
            display: flex;
            justify-content: center;
            width: 100%;
            position: relative;
            margin-top: 5rem;
            margin-bottom: 15rem;
        }
        
        .uploaded-posts{
            width: 100%;
            gap: 20px; 
            margin-block: 4rem;
            width: fit-content;
            transition: .2s ease;
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            
            // justify-content: center;
            transition: .2s ease;
            place-items: center;

            .no-results{
                display: flex!important;
                justify-content: center;
                align-self: center;
                width: 100%;
                // position: relative;
                margin-block: 8rem;
                grid-column: span 4; 
            }

             .product{
                position: relative;
               border-radius: 10px;
               padding: 1rem;
               display: inline-block;
               flex-direction: column;
               gap: 10px;
               border: 1px solid hsl(0, 0%, 90%);
               box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
               
               width: 250px;
               transition: .2s ease;
             
            }

            img{
                border-radius: 5px;
                width: 100%;
                height: 250px;
                object-fit: contain;
            }

            .product-info{
                display: flex;
                flex-direction: column;
                gap: 10px;

                .product-name{
                    font-weight: 600;
                    overflow: hidden; 
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-top: 10px;
                }

                .product-description{
                    line-height: 1.5;
                    overflow: hidden; 
                    text-overflow: ellipsis;
                    white-space: nowrap; /* Prevent line breaks */
                }


                .product-category{
                    display: none;
                }
    
                span{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
    
                    .product-price{
                        font-weight: bold;
                    }
    
                    button{
                    border: none;
                    background: #BBA58F;
                    cursor: pointer;
                    padding: 8px 15px;
                    border-radius: 10px;
                    color: white;
                    font-weight: bold;
                    transition: .2s ease-in-out;
                    font-size: 12px;
    
                    &:hover{
                        transform: scale(.95);
                    }
                    
                    }
                }
            }
        }

        .uploaded-posts.preview{
            margin-top:4rem ;
        }

        .product-modal {
            position: fixed;
            top: 0;
            left: 0;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
            /* Apply display: none for closed state */
            display: none;
            z-index: 5;
            width: 100%;
            // min-height: 100%;

            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: rgba(0, 0, 0, 0.5);
            overflow-x: hidden;

            .popup-details{
                width: fit-content;
                border-radius: 10px;;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: white;
                gap: .5rem;
                padding: 1.5rem;
             overflow-x: hidden;
             overflow-y: scroll;
             width: 100%;
             height: 100vh;
             
             &::-webkit-scrollbar{
                 display: none;
                }

                .closeIcon{
                    margin-top: 4rem;
                  background: none;
                  width: 80%;
                  font-weight: 600;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  
                  
                  .close{
                      padding: 1rem;
                      margin-bottom: 1rem;
                      width: fit-content;
                      display: flex;
                      justify-content: left;
                      align-items: center;
                      font-size: 15px;
                      cursor: pointer;
                      transition: .2s ease;
                      color: #333;


                      &:hover{
                          transform: scale(.98);
                      }
                  }
              }

              .container{
                display: grid;
                grid-template-columns:  repeat(2, 1fr);
                align-items: start;
                justify-content: center;
                gap: 2rem;
                width: 90%;
                margin: 0 auto;
                padding-bottom: 5rem;
           
                
                .right{
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    font-size: 1.3em;
                    padding: 1rem;
                    width: 100%;
                    align-items:flex-start;
                }

                .left{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;

                    overflow-x: auto;
                    white-space: nowrap;
                    background: hsl(0, 0%, 98%);
                    padding: 10px;
        
                    &::-webkit-scrollbar{
                        display: none;
                      }

                        
                img{
                    border-radius: 5px;
                    max-width: 400px;
                    height: 400px;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    object-fit: cover;
                    
                }

                }

              }
             

                p{
                    white-space: wrap;
                    font-size: .7em;
                    line-height: 1.75;
                }

                h5{
                    font-size: .5em;
                    color: #888;
                    margin-top: 1.5rem;
                }

                .color, .size{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .variation{
                    background: none;
                    padding: 7px 14px;
                    border: 1px solid #888;
                    cursor: pointer;
                    color: black;
                }


                .price{
                    font-weight: 700;
                    font-size: 1.25rem;
                }

                .buy-now{
                    display: flex;
                    flex-direction: row;
                   margin-top: 1.5rem;
                    width: 100%;
                    gap: 1rem;

                    button{
                        background: none;
                        border: 1px solid #888;
                        width: 7rem;
                        padding: 12px 0;
                        border-radius: 5px;
                        cursor: pointer;
                        width: 200px;
                        font-weight: 500;
                        color: black;
                        transition: .2s ease-in-out;

                        &:hover{
                            transform: scale(.95);
                        }
                    }
                }

                .share{
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                    padding-top: .5rem;
                    color: #333;

                    p{
                        display: flex;
                        align-items: center;
                        gap: .35rem;
                    }

                    button{
                        cursor: pointer;
                        padding-block: 10px;
                        background: #eee;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: .5em;
                        border: hsl(0, 0%, 73%);
                        border-radius: 10px;
                        font-weight: 500;
                        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                    }
                }
            }
          }

        .product-modal.open {
            display: flex; /* Show when open */
          }

          .popup{
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            z-index: 100000;
    
            .spinner {
                display: flex;
                gap: 1rem;
                align-items: center;
                background: green;
                color: white;
                font-weight: 600;
                padding: 8px 16px ;
                border-radius: 16px;
                cursor: pointer;
                transition: .3s ease;
                margin-bottom: 5rem;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                animation: slideUp 0.5s ease-out forwards;
                
                .icon{
                    font-size: 20px;
                  }

                &:hover{
                    transform: scale(.95);
                }

                @keyframes slideUp {
                    from {
                      transform: translateY(100%);
                      opacity: 0;
                    }
                    to {
                      transform: translateY(0);
                      opacity: 1;
                    }
                  }
              }

              .variation{
                background: hsl(54, 100%, 35%);
              }

              .removed{
              background: #ff4d4d;}
         }

         .addNumber{
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 100000;
            background: rgba(0, 0 , 0, 0.5);
    
            .checkout-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: inherit;
                align-items: inherit;
                gap: 1rem;
                text-align: center;
                background: white;
                padding: 5rem 3rem 3rem 3rem;
                border-radius: 1rem;
                
    
                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.5;
                    width: 30vw;
    
                    span{
                        font-weight: 600;
                    }
                }
    
                .buttons{
                    // margin-top: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 1rem;
                    margin-top: 2.5rem;
    
                    .a{
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .3s ease;
                        background: #eeebe3;
                        color: black;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
                        &:hover{
                            transform: scale(.9)
                        }
    
                     
                    }
    
                    a{
                        text-decoration: none;
                    }
    
                    .again{
                        color: white;
                        background: #FF7E00;
                    }
                }
    
              
        }
        }

}

.page{
    padding-top: 6.5rem;
}
}

@media screen and (max-width: 768px) {
    .theStore{
    
        .uploaded-posts-container{
    
            .search-container{
                position: relative;
    
                span{
                    width: 60%;
                   
                    input{
                        width: 100%;
                    }
                }
            }
    
          .categories-header{
            margin-left: 2.5%;
          }
            .categories-container{
                overflow-x: auto;
                white-space: nowrap;
                width: 95%;
                margin: 0 auto;
                margin-bottom: 1.6rem;
    
                &::-webkit-scrollbar{
                    display: none;
                  }
    
            }
    
            .loading-message{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: .75rem;
                justify-content: center;
    
                .loading-card{
                    font-size: 0.8em;
                    width: 11em;
    
                }
            }
    
            .uploaded-posts{
                // display: grid;
                // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                gap: .75rem;
    
    
                .product{
                    span{
                        flex-direction: column;
                        gap: 10px;
    
    
                        button{
                            width: 100%;
                        }
    
                        .product-price{
                            width: 100%
                        }
                    }
                }
    
            }
    
            .product-modal{
    
                .popup-details{
                //     max-width: 80%;
                // overflow: hidden;
                // justify-content:start;
    
                .closeIcon{
                    // margin-top: 400px;
                    width: 95%;
                }
    
                .container{
                           display: flex;
                            flex-direction: column;
                            margin-bottom: 8rem;
                            width: 100% !important;
    
                            .left{
                                width: 90vw;
                                display: flex;
                                gap: 1.6rem;
                                margin: 0 auto;
                            }
    
    
                            .right{
                                position: relative;
                                width: 90%;
                                padding: 0;
                                margin: 0 auto;
                            }
                }
    
                }
            }
    
            .addNumber{
    
                .checkout-container{
                width: 80vw;
                gap: 0;
                padding: 0;
                padding: 1.5rem;
    
                p{
                    width: 90%;
                }
    
                .buttons{
                    margin-top: 1rem;
                    width: 100%;
    
                    .a{
                        width: 100%;
                    }
                }
                }
            }
        }
    }
    }

@media screen and (max-width: 570px) {
    

    .theStore{

        .uploaded-posts-container{

            .categories-header{
                font-size: 15px;
                color: #888;
                text-align: left;
            }

            .categories-container{
                justify-content: flex-start;
                align-items: flex-start;
    
                &::-webkit-scrollbar{
                    display: none;
                  }
    
                  .categories{
                        overflow-x: auto;
                        white-space: nowrap;
        
                        &::-webkit-scrollbar{
                            display: none;
                          }
                          
                    }
            }

            .uploaded-posts{
                display: grid;
                // grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                grid-template-columns: repeat(2, 1fr) !important;
                gap: .75rem;
                justify-content: right;
                // align-items: center;
                width: fit-content;
                margin: 0 auto;
    
    
                .product{
                    font-size: 0.8em;

                    img{
                        height: 145px;
                        width: 100%;
                    }
    
                    span{
                        flex-direction: column;
                        gap: 10px;
    
    
                        button{
                            width: 100%;
                        }
    
                        .product-price{
                            width: 100%
                        }
                    }
                }
    
            }
        }
    }
}

//store layout

@media screen and (max-width: 570px) {
    

    .theStore{

        .uploaded-posts-container{

            .categories-header{
                font-size: 15px;
                color: #888;
                text-align: left;
            }

            .categories-container{
                justify-content: flex-start;
                align-items: flex-start;
    
                &::-webkit-scrollbar{
                    display: none;
                  }
    
                  .categories{
                        overflow-x: auto;
                        white-space: nowrap;
        
                        &::-webkit-scrollbar{
                            display: none;
                          }
                          
                    }
            }

            .uploaded-posts{
                display: grid;
                grid-template-columns: repeat(2, 1fr) !important;
                gap: .75rem;
                justify-content: right;
                // align-items: center;
                width: fit-content;
                margin: 0 auto;
    
    
                .product{
                    font-size: 0.8em;

                    img{
                        height: 145px;
                    }
    
                    span{
                        flex-direction: column;
                        gap: 10px;
    
    
                        button{
                            width: 100%;
                        }
    
                        .product-price{
                            width: 100%
                        }
                    }
                }
    
            }
        }
    }
}

//store layout

@media screen and (max-width: 940px) {
    .theStore{

        .uploaded-posts-container{

            .uploaded-posts{
             
                .product{
                 width: 200px;
               font-size: .9em;
                }
    
            }

            .product-modal{

                .popup-details {
                    // width: 100%;
                    // padding-inline: 30%;
                    
                    .container{
                        width: 80%;
                        font-size: .9em;
                        gap: 2rem;
                    }

                    .buy-now{
                        margin-bottom: 1rem;

                        button{
                        font-size: .8em;
                        }

                    }
                }
            }
        }
    }
}


@media screen and (max-width: 785px) {
    .theStore{

        .uploaded-posts-container{

            .uploaded-posts{
                grid-template-columns: repeat(2, 1fr) !important;

    
                .product{
                 width: 250px;
               font-size: .9em;
                }
    
            }
        }
    }
}

@media screen and (max-width: 615px) {
    .theStore{

        .uploaded-posts-container{

            .uploaded-posts{
    
                .product{
                 width: 200px;
               font-size: .9em;
                }
    
            }
        }
    }
}

@media screen and (max-width: 570px) {
    .theStore{

        .uploaded-posts-container{

            .uploaded-posts{
             
                .product{
                 width: 200px;
               font-size: .8em;

               img{
                height: 200px;
               }
                }
    
            }
        }
    }
}

@media screen and (max-width: 510px) {
    .theStore{

        .uploaded-posts-container{

            .uploaded-posts{
             
                .product{
                 width: 145px;
               font-size: .8em;


                }
    
                img{
                    width: 100% !important;
                    object-fit: contain;
                }
            }
        }
    }
}

@media screen and (max-width: 510px) {
.theStore{

    .uploaded-posts-container{

        .product-modal{

            .popup-details{

                .container{
                    font-size: .8em;
                }
            }
        }
    }
}
}

@media screen and (max-width: 1215px) {
    .theStore{

        .uploaded-posts-container{

            .uploaded-posts{
             
                grid-template-columns: repeat(3, 1fr);

            }}}}