.home{
    overflow: hidden;
    width: 100vw;
    // position: relative;

    // &::-webkit-scrollbar {
    //     display: none;
    //   }

.landing{
    background: rgba(0, 0, 0, 0.5) url('../../stock/homeLanding.jpg')no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing-content-container{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1.5rem;
        margin: 15rem 0;
    
    h1{
        color: white;
        letter-spacing: 1px;
        font-size: xx-large;
    }

    p{
        color: white;
        font-size: 18px;
        font-weight: lighter;
        line-height: 1.5;
    }

    .cta{
        padding: 10px 20px;
        border-radius: 20px;
        outline: none;
        border: none;
        font-weight: 600;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: .4rem;
        transition: .2s ease-in-out;

        &:hover{
            gap: .7rem;
        }

        .span{
            text-decoration: none;
            color: #333;
        }


    }

        }
        
    }

    .shop-section,
    .masterclass-section,
    .about-section, 
    .newsletter-section{
        width: 90vw;
        margin: 0 auto;
        margin-top: 5rem;
    }

    .shop-section{

        h1{
        margin-bottom: .7rem;
        font-weight: 500;
        }

        .shop-items{
            margin-top: 3rem;
            display: flex;
            gap: 2rem;

            a{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-width: 200px;
                max-width: 200px;
                height: 350px;
                text-decoration: none;
                border-radius: 5px;
                padding: 1.5rem 2rem;

                h2{
                color: white;
                font-weight: 400;
                }

                h3{
                color: white;
                font-size: smaller;
                letter-spacing: 1px;
                font-weight: 400;

                }
            }
            
            .clm{
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;
            }

            .one{
                background: rgba(0, 0, 0, 0.5) url('../../stock/sofa.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundOne 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundOne {
                        0% { background-image: url('../../stock//table.jpg'); }
                        50% { background-image: url('../../stock/sofa3.jpg'); }
                        100% { background-image: url('../../stock/sofa4.jpg'); }
                      }
            }

            .two{
                background: rgba(0, 0, 0, 0.5) url('../../stock//table.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundTwo 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundTwo {
                        0% { background-image: url('../../stock//table2.jpg'); }
                        50% { background-image: url('../../stock//table3.jpg'); }
                        100% { background-image: url('../../stock//table4.jpg'); }
                      }
            }

            .three{
                background: rgba(0, 0, 0, 0.5) url('../../stock//bed.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundThree 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundThree {
                        0% { background-image: url('../../stock//bed2.jpg'); }
                        50% { background-image: url('../../stock//bed3.jpg'); }
                        100% { background-image: url('../../stock//bed4.jpg'); }
                      }
            }

            .four{
                background: rgba(0, 0, 0, 0.5) url('../../stock//light.jpg')no-repeat ;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                position: relative;
                transition: transform  0.3s ease-in-out;

                &:hover{
                    animation: changeBackgroundFour 5s infinite alternate-reverse;
                    }

                    @keyframes changeBackgroundFour {
                        0% { background-image:  url('../../stock//light2.jpg'); }
                        50% { background-image:  url('../../stock//light3.jpg'); }
                        100% { background-image:  url('../../stock//light4.jpg'); }
                      }
            }

        }

        .shop-cta{
            padding: 10px 20px;
            border-radius: 20px;
            outline: none;
            border: none;
            font-weight: 600;
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: .4rem;
            transition: .2s ease-in-out;
            margin-top: 2rem;
            background: #bba58f;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    
            &:hover{
                gap: .7rem;
            }

            .span, .ctaArrow{
            color: white;
            text-decoration: none;
        }


        }
     
    }

    .masterclass-section{

        .m-container{
            display: grid;
            grid-template-columns: 50% 50%;
            place-items: center;
            justify-content: space-between;

            .m-sec-left{
                position: relative;
                display: grid;
                width: 90%;
                justify-content: center;
                height: 25em;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

                background: url('../../stock/master2.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                border-radius: 5px;
            }

            .m-sec-right{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h1{
                    span{
                        color: #523d35;
                    }
                }

                p{
                    line-height: 1.5;
                }

                a{
                    width: fit-content;
                    padding: 8px 20px;
                    border-radius: 25px;
                    outline: none;
                    border: none;
                    font-weight: 600;
                    display: flex;
                    cursor: pointer;
                    gap: .4rem;
                    transition: .2s ease-in-out;
                    align-items: center;
                    text-decoration: none;
                    color: #333;
                    background: #bba58f;
                    color: white;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

            
                    &:hover{
                        gap: .7rem;
                    }
            
                }
            }
        }
    }

    .about-section{

        .abt-sec-container{

            display: grid;
            grid-template-columns: 50% 50%;
            place-items: center;
            justify-content: space-between;


            .abt-sec-left{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h1{
                    span{
                        color: #523d35;
                    }
                }

                p{
                    line-height: 1.5;
                }

                a{
                    text-decoration: none;
                    color: #523d35;
                    padding-bottom: .5rem;
                    border-bottom:1px solid #523d35;
                    width: fit-content;
                    font-weight: 600;
                    transition: 0.3s ease-in-out;

                    &:hover{
                        border-bottom: 1px solid #bba58f;
                        color: hsl(17, 21%, 46%);
                        
                    }
                }
            }

            .abt-sec-right{
                width: 90%;
                justify-content: center;
                height: 25em;

                background: url('../../stock/master.jpg')no-repeat;
                background-size: cover;
                background-position: center;
                background-blend-mode: overlay;
                border-radius: 5px;
            }
        }

    }

    .newsletter-section{
        text-align: center;
        margin-bottom: 3rem;

        .newsletter-container{
            width: 70%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            
                span{
                    color: #523d35;
                }
            

            p{
                margin-bottom: 1rem;
                line-height: 1.5;
            }

            .sub-container{
                position: relative;
                border: 1px solid #523d35;
                padding: .7rem 1rem;
                border-radius: 5.5rem;
                display: flex;
                margin: 0 auto;
                gap: 4rem;
                width: fit-content;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            
                input{
                    // padding: 1rem 0;
                    outline: none;
                    border: none;
                }

                button{
                    padding: 8px 15px;
                    border: none;
                    background: #523d35;
                    border-radius: 20px;
                    color: white;
                    cursor: pointer;
                    transition: .3s ease-in-out;

                    &:hover{
                        transform: scale(.95);
                    }
                }
            }
        }

    }

}

@media screen and (max-width: 1080px) {
.home{

    .landing{
        
        .landing-content-container{
            width: 90%;
            
            p{
                line-height: 1.5;
            }
        }
    }

    .shop-items{
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none; /* Or width: 0; or height: 0; */
          }
    }

    .masterclass-section{

        .m-container{
            display: flex;
            flex-direction: column;
            gap: 3rem;

            .m-sec-left{
                width: 100%;
            }
        }
    }

    .about-section{

        .abt-sec-container{
            display: flex;
            flex-direction:column-reverse;
            gap: 3rem;

            .abt-sec-right{
                width: 100%;
            }
        }
    }
} 
}

@media screen and (max-width: 720px) {
  .newsletter-section{
    position: relative;
      
      .newsletter-container{
        min-width: 100%;

        .sub-container{
            gap: 2rem!important;
        }
    } 
    
}
}