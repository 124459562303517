.userNavbar{
    margin-bottom: 3rem;
    width: 99.5%;
    // overflow-x: hidden;
    z-index: 10;
    position: fixed;
    background: white;
    padding-bottom: 1rem;
    top: 0;

    
    .userNav-container{
        width: 95%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        border: 0.5px solid #8888;
        border-radius: 25px;
        margin-top: 1rem;
        background: white;

        .active-link{

            &::after{
                position: absolute;
                bottom: -30%;
                left: 35%;
                margin: 0 auto;
                content: "";
                width: 20px;
                height: 2px;
                border-radius: 25px;
                transform: scaleX(1);
                background:#e8d9cd;
                transform-origin: center;
                opacity: 1;
                transition: 0.3s ease-in-out, visibility ease-in;
            }
    
    
        }

          .logo-container{
            display: flex;
            text-decoration: none;
            color: #000;
            margin-left: 1rem;
            align-items: center;
            
            img{
                width: 50px;
                height: 50px;
            }
    
            .logo{
                letter-spacing: 4px;
                
                span{
                    font-weight: 700;
                }
            }
        }

        

        .userNavLinks{
            display: flex;
            gap: 2rem;

            li{
                list-style: none;
                position: relative;
                
                &::after{
                    position: absolute;
                    bottom: -30%;
                    left: 35%;
                    margin: 0 auto;
                    content: "";
                    width: 20px;
                    height: 2px;
                    border-radius: 25px;
                    transform: scaleX(0);
                    background: #bba58f;
                    transform-origin: center;
                    opacity: 0;
                    transition: 0.3s ease-in-out, visibility ease-in;
                }
    
                &:hover::after{
                    transform: scaleX(1);
                    transform-origin: center;
                    opacity: 1;
                }
            }

            a{
                color: #333;
                text-decoration: none;
            }
        }

        .userControls{
            display: flex;
            align-items: center;
            gap: 1.5rem;
            margin-right: 2rem;
            position: relative;
            font-weight: 500;

            .app-icon{
                font-size: 25px;
                cursor: pointer;
            }

            .notifs{
                font-size: 1px;
            }

            .notifs, .cart{
                transition: 0.3s ease-in-out;
            
                &:hover{
                    animation: shake 0.4s ;
                }
            }


            .cart-cont{
                position: relative;
                display: flex;
                align-items: center;
            // border: 1px solid red;
                justify-content: center;

                &:hover .cart-total{
                    animation: shake 0.4s ;
                }

                .cart-container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .cart-total{
                        position: absolute;
                        border: 2px solid white;
                        right: 5px;
                        top: 5px;
                        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                        border-radius: 50px;
                        background: hsl(17, 21%, 26%);
                        color: white;
                        padding: 2px;
                        width: 11px;
                        height: 11px;
                        font-size: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 4;
                        transition: 0.3s ease-in-out;
                    }
                }
            }


            @keyframes shake {
                0% { transform: translateX(0); } /* Start position */
                25% { transform: translateX(-2px); } /* Move left */
                50% { transform: translateX(2px); } /* Move right */
                75% { transform: translateX(-2px); } /* Move left again */
                100% { transform: translateX(0); } /* Return to original position */
              }

              .user{
                border: 1px solid transparent;
                border-radius: 50%;
                padding: 5px;
                transition: .3s ease-in-out;
                // width: 35px;
                // height: 35px;
                object-fit: cover;
                font-size: 27.5px;

                &:hover{
                    transform: scale(.8);
                    border: 1px solid #888;
                }
              }

              .currentUserInfo{
                  position: absolute;
                  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                  width: 10rem;
                  margin-top: 135%;
                  height: 10.5rem;
                  border-radius: 10px;
                  z-index: 9;
                  background: white; 
                  right: 0;

                  .currentUserInfo-content{
                    padding: 1rem;
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    a{
                        text-decoration: none;
                        color: #333;
                        padding: 8px 1px;
                        transition: .3s ease-in-out;
                        width: 100%;
                        text-align: center;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        gap: .7rem;
                        padding: .5rem;

                        &:hover{
                            background: hsl(17, 21%, 95%);

                        }

                        .icon{
                            font-size: 18px;
                        }
                    }

                    button{
                        width: 100%;
                        padding: 8px 1px;
                        border: none;
                        background: hsl(17, 21%, 95%);
                        border-radius: 5px;
                        cursor: pointer;
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
                        font-weight: 500;
                        transition: all .2s ease-in-out;

                        &:hover{
                            transform: scale(.9);
                            background: #FF7E00;
                            color: white;
                            font-weight: 500;
                        }
                    }
                    }
              }
        }
        
        
        .app-icon{
            font-size: 27.5px;
            cursor: pointer;
            padding:  0.6rem;
            user-select: none;
            // border: 2px solid red;
        }

        .mobile-menu-btn{
            // background: red;
            position: relative;
            .cart-total{
                position: absolute;
                border: 2px solid white;
                left: 25px;
                top: 2;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                border-radius: 50px;
                background: hsl(17, 21%, 26%);
                color: white;
                padding: 2px;
                width: 11px;
                height: 11px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 4;
                transition: 0.3s ease-in-out;
                user-select: none;
            }
        }
        .menu-icon{
            // border: 2px solid red;
        }

        .mobile-view{
            display: none;
        }
     
    }

    .mobile-menu-container{
        width: 100vw;
        height: 100vh;
        overflow-x: hidden;
        z-index: 100;
        // background: rgba(0, 0, 0, 1);
        background: rgba(255, 255, 255, 0.001);
         backdrop-filter: blur(5px);
        display: none;
        transform-origin: center;
        transition: .4s ease-in-out;
        overflow-x: hidden;
        top: 0;
        left: 0;
        position: fixed;

        .mobile-menu{
            position: absolute;
            top: 5rem;
            right: 1.5rem;
            height: 22rem;
            background: #fff;
            border-radius: 30px;  
            width: 300px;
            // box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            overflow: hidden;

            .menu-content{
                font-weight: 500;
                // padding: 2rem;
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;    
                
            .cancel-btn{
                font-size: 30px;
                position: relative;
                left: 70%;
                cursor: pointer;
                transition: .3s ease-in-out;
                user-select: none;
                // margin-bottom: 2rem;
                
                &:hover{
                    transform: scale(1.1);
                }
            }

            span{
                display: flex;
                width: fit-content;
                font-size: 1.6em;
                gap: 1rem;

                .cart-cont{
                    

                    &:hover .cart-total{
                        animation: shake 0.4s ;
                    }
    
                    .cart-container{
                        position: relative;
                        cursor: pointer;

                        .cart{
                        font-size: 1.25em;
                        }

                        .cart-total{
                            position: absolute;
                            border: 2px solid white;
                            right: -4px;
                            top: -4px;
                            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                            border-radius: 50px;
                            background: hsl(17, 21%, 26%);
                            color: white;
                            padding: 2px;
                            width: 11px;
                            height: 11px;
                            font-size: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 4;
                            transition: 0.3s ease-in-out;
                        }
                    }
                }

                
            }

                .mobilepage-links{
                    margin-top: 1.6rem;
                    display: flex;
                    flex-direction: column;
                    list-style: none;
                    gap: 1.5rem;
                    font-size: 18px;
    
                
                    a{
                        text-decoration: none;
                        transition: .2s ease-in-out;
                        color: #333;
    
                        &:hover{
                            font-size: medium;
                        }
                    }
                }
            }
        }
    }
}
.mobile-menu-container.is-visible{
    display: block;
    transform-origin: center;
    transition: .4s ease-in-out;

}

@media screen and (max-width: 850px) {
.userNavbar{

    .userNav-container{

        .userNavLinks{
            display: none;
        }
    }
}
}

@media screen and (max-width: 480px) {
.userNavbar{

    .userNav-container{

        .logo-container{
            font-size: smaller;
        }

    }
}
}


@media screen and (max-width: 550px) {
.userNavbar{

    .userNav-container{

        .desktop-content{
            display: none;
        }

        .mobile-view{
            display: block;
        }

        .app-icon{
          margin-right: 1rem;
        }

        // .notifs{
        //     font-size: 11rem;
        // }
    }
}
}
