.adminHome{

    &::-webkit-scrollbar {
        display: none;
      }


   
    .adminUploads{

        .admin-current-page{
            margin-block: 2rem;;
        }


        .search-container{
            margin-bottom: 2rem;
            display: flex;
            flex-direction: row;
            gap: .7rem;

            span{
                display: flex;
                align-items: center;
                background-color: hsl(0, 0%, 95%);
                padding-inline: .7rem;
                padding-right: 10rem;
                border-radius: 30px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                input{
                    border-radius: 30px;
                    padding: .7rem;
                    padding-right: 2rem;
                    border: none;
                    outline: none;
                    background-color: hsl(0, 0%, 95%);

                }
                .search-icon{
                    font-size: 18px;
                }
            }


            button{
                padding-inline: 1rem;
                border-radius: 50px;
                border: none;
                cursor: pointer;
                color: white;
                background: hsl(0, 0%, 10%);
                transition: .2s ease-in-out;

                &:hover{
                    transform: scale(.95);
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                }
            }
        }


        .categories-container{
            margin-bottom: 2rem;

            .categories{
                margin-top: 1rem;
                display: flex;
                align-items: center;
                gap: 2rem;


                .category-name{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    gap: .7rem;
                    align-items: center;

                    button{
                        background: none;
                        border: none;
                        border-radius: 50%;
                        cursor: pointer;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }
                    
                    img{
                        width: 35px;
                        transition: transform .3s ease-in-out;
                        // padding: 10px;

                        &:hover{
                            transform: scale(1.05);
                        }
                    }

                    p{
                        font-size: 12px;
                        font-weight: 500;
                    }

                }
            }
        }

        .loading-message{
            margin-bottom: 3rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem;


            .loading-card{
                width: 12em;
                display: flex;
                flex-direction: column;
                padding: 1rem;
                border-radius: 10px;
                gap: 1rem;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;


                .loading-img{
                    height: 150px;
                    width: 100%;
                    border-radius: 10px;
                }

                .loading-text{
                    height: 25px;
                    width: 80%;
                    border-radius: 10px;
                }

                .loading-text-II{
                    height: 20px;
                    width: 60%;
                    border-radius: 10px;
                }

                .loading-img,
                .loading-text,
                .loading-text-II{
                    background-color: #ededed;
                    background: linear-gradient(
                        100deg,
                        rgba(255,255,255,0) 40%,
                        rgba(255,255,255, .5) 50%,
                        rgba(255,255,255,0) 60%
                    ) #ededed;
                    background-size: 200% 100%;
                    background-position-x: 100%;
                    animation: 1s loading ease-in-out infinite;
                }
                @keyframes loading {
                    to{
                         background-position-x: -20%;
                    }
                }

            }
        }

        .uploads-container{
            margin-bottom: 3rem;
            padding-bottom: 3rem;
            padding-left: 20px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem;

               // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            // justify-content: center;
            // width: fit-content;
            // margin: 0 auto;


            .no-results{
                width: 100%;
                padding-block: 5rem;
                display: flex;
                font-weight: 500;
                justify-content: center;
            }


            .product{
             position: relative;
            background: hsl(0, 0%, 99%);
            border-radius: 10px;
            padding: 1rem;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 12em;

            img{
                border-radius: 5px;
                max-width: 100%;
                // max-height: 150px;
                object-fit: cover;
            }

            .product-info{
                display: flex;
                flex-direction: column;
                gap: 10px;

                .product-name{
                    font-weight: 600;
                    line-height: 1.5;
                    overflow: hidden; 
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .product-description{
                    // width: 90%;
                    line-height: 1.5;
                    overflow: hidden; 
                    text-overflow: ellipsis;
                    white-space: nowrap; /* Prevent line breaks */

                }


                .product-category{
                    display: none;
                }
    
                .product-price{
                    font-weight: bold;
                }
                span{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
    
    
                    button{
                    border: none;
                    cursor: pointer;
                    padding: 8px 15px;
                    width: 5rem;
                    border-radius: 10px;
                    color: white;
                    font-weight: bold;
                    transition: .2s ease-in-out;
                    
                    &:hover{
                        transform: scale(.95);
                    }
                }
                
                    button.delete-btn{
                    background: #B22222;
                    }
                    button.edit-btn{
                        background: #FFA500;
                    }
                }
            }

            }
        }

    }
        
    .modal{
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 10000000000;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content{
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            background: white;
            padding: 2rem;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            input, textarea{
                border: 1px solid #ccc;
                padding: 10px;
                border-radius: 4px;

                &:focus {
                    outline: none; /* Removes the default browser focus outline */
                    border-color: #28a745; /* Success green border */
                    box-shadow: 0 0 5px rgba(40, 167, 69, 0.5); /* Adds a subtle glow effect */
                  }
            }

            .edit-buttons{
                display: flex;
                align-items: center;
                justify-content: space-between;

                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // width: 5rem;
                    padding: 8px 15px;
                    color: white;
                    font-weight: bold;
                    border: none;
                    border-radius: 8px;
                    gap: 10px;
                    cursor: pointer;
                    transition: .2s ease;

                    .icon{
                        font-size: 1rem;
                    }

                    &:hover{
                        transform: scale(.95);
                    }
                }

                button.close{
                    background: #dc3545;
                }

                button.upload{
                    background: #28a745;
                }
            }

                          
          .stock-toggle {
            display: flex;
            align-items: center;
            gap: 1rem;
            // justify-content: space-around; /* Space out the buttons */   
            margin: 10px 0;
            font-weight: 500;
          }
          
          .stock-btn {
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
          }
          
          .stock-btn.active {
            background-color: #4caf50; /* Green for active */
            color: white;
          }
          
          .stock-btn:not(.active):hover {
            background-color: #f0f0f0; /* Light gray on hover for inactive */
          }
        }
    }
}



@media screen and (max-width: 791px) {
.adminHome{

    .admin-current-page{
        text-align: right;
        padding-right: 2rem;
    }

    .adminUploads{

        .search-container{
            position: relative;

            span{
                padding-right: 0;
            }
        }

        .categories-container{
            

            .categories{
                overflow-x: auto;
                white-space: nowrap;

                &::-webkit-scrollbar{
                    display: none;
                  }
                  
            }
        }

        .loading-message{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: .75rem;
            justify-content: center;

            .loading-card{
                font-size: 0.8em;
                width: 11em;

            }
        }

        .uploads-container{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: .75rem;
            padding-left: 0;
            

            .product{
                font-size: 0.9em;
                // width: 11em;

                span{
                    // flex-direction: column;
                    gap: 10px;


                    button{
                        width: 100%;
                    }

                    .product-price{
                        width: 100%
                    }
                }
            }
        }
    }
}
}

@media screen and (max-width: 690px) {
    .adminHome{
    
    
        .adminUploads{

    
            .loading-message{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: .75rem;
                justify-content: center;
    
                .loading-card{
                    font-size: 0.8em;
                    width: 11em;
    
                }
            }
    
            .uploads-container{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: fit-content;
                margin:  0 auto;
                justify-content: space-around;
                gap: 1.5rem;
                
    
                .product{
                    font-size: 0.8em;
                    // width: 19em;
    
                }
            }
        }

        .modal{

            .modal-content{
                width: 70%;
                font-size: 90%;
                padding: 1.5rem;
                
            }
                
                
  

        }
    }
    }

    @media screen and (max-width: 480px) {
        .adminHome{
        
        
            .adminUploads{
    
        
                .loading-message{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: .75rem;
                    justify-content: center;
        
                    .loading-card{
                        font-size: 0.8em;
                        width: 11em;
        
                    }
                }
        
                .uploads-container{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: .75rem;
                    width: fit-content;
                    margin:  0 auto;
                    justify-content: space-around;
                    
        
                    .product{
                        font-size: 0.8em;
                        width: 140px;
        
                    }
                }
            }
        }
        }