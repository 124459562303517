.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f8f8;
    color: #333;
  }
  
  .not-found-container h1 {
    font-size: 6rem;
    margin: 0;
  }
  
  .not-found-container h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
  }
  
  .not-found-container p {
    font-size: 1.2rem;
    margin: 0.5rem 0 1.5rem 0;
  }
  
  .home-link {
    padding: 0.75rem 1.5rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #0056b3;
  }
  

  /* Responsive Styles */
@media (max-width: 768px) {
    .not-found-container h1 {
      font-size: 4rem;
    }
  
    .not-found-container h2 {
      font-size: 1.5rem;
    }
  
    .not-found-container p {
      font-size: 1rem;
    }
  
    .home-link {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-container h1 {
      font-size: 3rem;
    }
  
    .not-found-container h2 {
      font-size: 1.25rem;
    }
  
    .not-found-container p {
      font-size: 0.9rem;
    }
  
    .home-link {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }