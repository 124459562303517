.navigation{
    display: flex;
    justify-content: center;
    position: relative;
    // display: none;
    z-index: 100000;
    }

.navbar{
    width: 95vw;

    display: flex;
    justify-content: space-between;
    align-items: center;
    // box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border: 0.5px solid hsl(0, 0%, 73%);
    border-radius: 25px;
    margin-top: .95rem;
    margin-bottom: 2em;
    position: fixed;
    top: 0;
    z-index: 5;
    background: #fff;


    .mobile-view{
        display: none;
    }


    .active-link{

        &::after{
            position: absolute;
            bottom: -30%;
            left: 35%;
            margin: 0 auto;
            content: "";
            width: 20px;
            height: 2px;
            border-radius: 25px;
            transform: scaleX(1);
            background:#e8d9cd;
            transform-origin: center;
            opacity: 1;
            transition: 0.3s ease-in-out, visibility ease-in;
        }


    }

    .logo-container{
        display: flex;
        text-decoration: none;
        color: #000;
        margin-left: 1rem;
        align-items: center;
        
        img{
            width: 50px;
            height: 50px;
        }

        .logo{
            letter-spacing: 4px;
            
            span{
                font-weight: 700;
            }
        }
    }

    .page-links{
        display: flex;
        list-style: none;
        gap: 1.5rem;
        
        li{

            position: relative;

            &::after{
                position: absolute;
                bottom: -30%;
                left: 35%;
                margin: 0 auto;
                content: "";
                width: 20px;
                height: 2px;
                border-radius: 25px;
                transform: scaleX(0);
                background: #bba58f;
                transform-origin: center;
                opacity: 0;
                transition: 0.3s ease-in-out, visibility ease-in;
            }

            &:hover::after{
                transform: scaleX(1);
                transform-origin: center;
                opacity: 1;
            }
        }
        
        a{
            color: black;
            text-decoration: none;
            padding: 2px;
        }



    }

    .app{
        display: flex;
        gap: .7rem;
        align-items: center;
        margin-right: 2rem; 
        position: relative;

        .app-icon{
            font-size: 1.5rem;
          cursor: pointer;
          padding:  0.6rem;
          user-select: none;
        }

        .notif-bars{
            position: relative;
        }

        .notif-bar{
            position: absolute;
            margin-top: 10%;
            background: white;
            width: 11.5rem;
            height: 10rem;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transition: all .4s ease-in-out;
            transform-origin: center;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            a{
                text-decoration: none;
                padding-right: 3px;
            }

        }

     

        .bar1, .bar2{
            opacity: 0;
            transition: opacity 0.5s;
            transition-delay: 1s;
        }
        
        .notifs:hover + .bar1,
        .cart:hover + .bar2
        {
            opacity: 1;
            transition-delay: 0s
        }

        // .menu,
        // .login-btn{
        //     border:1px solid red;
        // }

        .menu{
            transition: .5s ease-in-out;
            
            &:hover{
                transform: scale(1.1);
            }

        }
        
        .notifs{
            transition: 0.3s ease-in-out;
            
            &:hover{
                animation: shake 0.4s ;
            }
        }

        .cart{
            transition: 0.3s ease-in-out;

            &:hover{
                animation: shake 0.4s ;
            }
        }
        
        @keyframes shake {
            0% { transform: translateX(0); } /* Start position */
            25% { transform: translateX(-2px); } /* Move left */
            50% { transform: translateX(2px); } /* Move right */
            75% { transform: translateX(-2px); } /* Move left again */
            100% { transform: translateX(0); } /* Return to original position */
          }

        .login-btn{
            font-size: 16px;
            cursor: pointer;
            margin-left: .5rem;
            border: 1px solid transparent;
            transition: 0.3s ease-in-out;
            padding: 5px 8px;
            border-radius: 15px;
            border: 1px solid #8888;
            user-select: none;
            outline: none;
            text-decoration: none;
            color: #333;


            &:hover{
                transform: scale(.95)
            }
        }
    }


}

.mobile-menu-container{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    z-index: 100;
    // background: rgba(0, 0, 0, 1);
    background: rgba(255, 255, 255, 0.001);
     backdrop-filter: blur(5px);
    display: none;
    transform-origin: center;
    transition: .4s ease-in-out;
    overflow-x: hidden;
    top: 0;
    left: 0;
    position: fixed;

    
    .mobile-menu{
        position: absolute;
        top: 5rem;
        right: 1.5rem;
        height: 23rem;
        background: #fff;
        border-radius: 30px;  
        width: 300px;
        // box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        overflow: hidden;
        
        .menu-content{
            margin: 2rem;
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;

            .cancel-btn{
                font-size: 30px;
                position: relative;
                left: 70%;
                cursor: pointer;
                transition: .3s ease-in-out;
                user-select: none;
                
                &:hover{
                    transform: scale(1.1);
                }
            }

            span{
                margin-top: 2rem;
                font-size: 30px;
                display: flex;
                gap: 1rem;

                .span-icon{
                    cursor: pointer;
                    &:hover{
                        animation: shake 0.4s ;
                    }
                }
            }

            .mobilepage-links{
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                list-style: none;
                gap: 1.5rem;
                font-size: 18px;

            
                a{
                    text-decoration: none;
                    transition: .2s ease-in-out;
                    color: #333;

                    &:hover{
                        font-size: medium;
                    }
                }
            }
        }
    
}


}
.mobile-menu-container.is-visible{
    display: block;
    transform-origin: center;
    transition: .4s ease-in-out;

}

@media screen and (max-width: 1080px) {
.navbar{
    border-radius: 50px;

    .mobile-view{
        display: block;
    }
    .desktop-view{
        display: none;
    }

    .logo-container{
        margin-left: 0;
        font-size: .7em;

       
    }
    
    .page-links{
        display: none;
    }

    .app{
        gap: 0.3rem;
        margin-right: 1rem;

        .notif-bars{
            position: relative;
            display: none;
        }

        .login-btn{
            // display: none;
            font-size: .8em;
        }
    }
}
}