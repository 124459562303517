.masterclass{
    width: 100%;

    .masterclass-enroll,
    .masterclass-details,
    .masterclass-structure,
    .masterclass-benefits,
    .next-step{
        width: 90vw;
        margin: 0 auto;
        margin-top: 5rem;
    }
    
    .masterclass-landing{
        background: rgba(0, 0, 0, 0.5) url('../../stock/masterclassh.jpg')no-repeat;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        position: relative;
        
        
        h1{
            padding: 2rem 4rem;
            padding-top: 7rem;
            color: white;
            letter-spacing: 1px;
        }

    }

    .masterclass-enroll{

        display: grid;
        grid-template-columns: 50% 48%;
        justify-content: space-between;
        align-items: center;


        .enroll-left{
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h1{
                span{
                    color: #bba58f;
                }
            }

            p{
                line-height: 1.7;
                color: #000;
            }

            .class-cta{
                display: flex;
                align-items: center;
                gap: 1rem;
                margin-top: .5rem;

                a{
                    display: inherit;
                    align-items: inherit;
                    gap: .5rem;
                    color: #333;
                    text-decoration: none;
                    border: 1px solid #888;
                    padding: 7px 15px;
                    border-radius: 20px;
                    transition: .3s ease-in-out;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

                        
                        &:hover{
                            transform: scale(.95);
                        }

                        &:nth-child(1){
                            background: #000;
                            color: white;
                            border: none;
                            padding: 9px 15px;
                        }

                }
            }
        }

        .enroll-right{
                background: url('../../stock/enroll3.jpg')no-repeat;
            background-size: cover;
            background-position: center;
            background-blend-mode: overlay;
            position: relative;
            height: 20rem;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        }

    }

    .masterclass-details{
        text-align: center;

        h1{
            span{
                color: #bba58f;
            }
        }
   
        .m-learn{
            display: flex;
            align-items: center;
            gap: 2em;
            margin-top: 2rem;

            .learn-box{
                display:inherit;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                padding: 2rem;
                border-radius:10px;
                height: 15rem;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

                &:nth-child(1){
                    background: #95CBC8;
                }

                &:nth-child(2){
                    background: #F4C4AE;
                }

                &:nth-child(3){
                    background: #F1BE40;
                }


                .learn-icon{
                    font-size: 50px; 
                }

                p{
                    line-height: 1.5;
                }
            }
        }
    }

    .masterclass-structure{
        
        h1{
        text-align: center;
        }

        .structure-container{
            display: grid;
            grid-template-columns: 50% 48%;
            justify-content: space-between;
            margin-top:3rem;

            .structure-left{
                display:inherit;
                grid-template-columns: 50% 46%;
                position: relative;
                width:100%;
                justify-content: space-between;

                .big-img{
                    background: url('../../stock/course.jpg')no-repeat;
                    background-size: cover;
                    background-blend-mode: overlay;
                    position: relative;
                    border-top-left-radius: 20px;
                    background-position: 35% 10%; 
                }

                span{
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    width: 100%;
                    position: relative;
                    gap: 1.4rem;
                    height: 90%;
                    align-self: center;
                    
                    .small-img1{
                        background: url('../../stock/course2.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        position: relative;
                        width: 90%;
                        border-top-right-radius: 20px;
                        border-bottom-right-radius: 20px;
                    }

                    .small-img2{
                        background: url('../../stock/course3.jpg')no-repeat;
                        background-size: cover;
                        background-position: center;
                        background-blend-mode: overlay;
                        position: relative;
                        width: 90%;
                        border-top-right-radius: 20px;
                        border-bottom-right-radius: 20px;

                    }
                }
            }

            .structure-right{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .structure-box{
                    display: flex;
                    align-items: center;
                    gap: 1.5rem;

                    .structure-icon{
                        font-size: 20px;
                        background: hsl(30, 24%, 86%);
                        padding: .7rem;
                        border-radius: 7px;
                    }

                    .structure-dets{
                        width: 70%;

                        p{
                            line-height: 1.5;
                            margin-top: .3rem;
                        }
                    }
                }
            }
        }
    }

    .masterclass-benefits{
        
        h1{
            text-align: center;

            span{
                color: #bba58f;
            }
        }

        .benefits-container{
            display: grid;
            grid-template-columns: repeat(3, 3fr);
            gap: 2rem;
            margin-top: 2.5rem;

            .benefit-box{
                display: flex;
                flex-direction: column;
                gap: .8rem;
                color: white;
                padding: 2rem;
                border-radius: 10px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


                &:nth-child(1){
                    background: #37374F;
                }
                &:nth-child(2){
                    background: #344F43;
                }
                 &:nth-child(3){
                    background: #878CE9;
                }
                 &:nth-child(4){
                    background: #6F7581;
                } 
                &:nth-child(5){
                    background: #EEC75F;
                } 
              

                .benfit-icon{
                    font-size: 25px;
                }

                p{
                    line-height: 1.5;
                }
            }
        }
    }


    .next-step{
        text-align: center;
        display:flex;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 3rem;

        h1{
            span{
                color: #bba58f;
            }
        }

        p{
            width: 70%;
            margin: 0 auto;
            line-height: 1.5;
        }

        .next-links{
            display: flex;
            gap: 1rem;
            align-items: center;
            margin: 0 auto;

            a{
                color: #000;
                text-decoration: none;
                padding: 8px 15px;
                border-radius: 20px;
                transition: .3s ease-in-out;

                &:hover{
                    transform: scale(.9);
                }
                
                &:nth-child(1){
                    border: 1px solid black;
                }

                &:nth-child(2){
                    background: black;
                    color: white;
                }
            }
        }
    }

    
    .form-page{
        display: grid;
        grid-template-columns: 60% 40%;

        .form-container{
            width: 85%;
        margin: 3rem auto;

            .enroll-header{
                line-height: 1.75;
                margin-bottom: 2.5rem;
                
                p{
                    color: #888;
                    font-size: 12px;
                }
            }

            form{
                display: flex;
                flex-direction: column;
                gap: 1rem;

                input{
                    margin-bottom: .5rem;
                    padding-block: 1rem;
                    border: none;
                    border-bottom: 1px solid #888;
                    outline: none;
                    transition: .2s ease;

                    &:focus{
                        transform: scale(.97);
                    }
                }

                button{
                    border: none;
                    margin-top: 1rem;
                    padding: .75rem 1rem;
                    background: #37374F;
                    width: fit-content;
                    color: white;
                    font-weight: 500;
                    border-radius: 20px;
                    cursor: pointer;

                    &:hover{
                        background: hsl(240, 18%, 36%);
                    }
                }
            }
        }

        .faqs{
            background: #F1F3FF;
            padding: 4rem;
            color: #37374F;

            .Faqs {
                .acc-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    font-size: 80%;
            
                    input {
                        position: absolute;
                        opacity: 0;
                        z-index: -1;
                    }
            
                    .accordion {
                        display: grid;
                    }
            
                    .item {
                        margin-bottom: 20px;
                        border-radius: 8px;
                        overflow: hidden;
                        padding-top: 1rem;
                    }
            
                    .title {
                        display: flex;
                        padding-block: 1rem;
                        justify-content: space-between;
                        color: #252525;
                        font-weight: 600;
                        cursor: pointer;
            
                        &::after {
                            content: "\002B"; /* Default Plus Sign */
                            font-size: 20px;
                            width: 1rem;
                            height: 1rem;
                            text-align: center;
                            transition: .2s ease;
                        }
                    }
            
                    .acc-content {
                        padding: 0 1rem;
                        color: #828282;
                        line-height: 2;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
                        border-bottom: 1px solid #200b0b;
                    }
            
                    /* Toggling the accordion */
                    input:checked + .title::after {
                        content: "\2212"; /* Minus sign when opened */
                        transform: rotate(180deg);
                    }
            
                    input:checked + .title + .acc-content {
                        max-height: 500px; /* Adjust height accordingly */
                        padding: 1rem;
                    }
            
                    /* Unchecking when clicking again */
                    input:not(:checked) + .title + .acc-content {
                        max-height: 0;
                        padding: 0 1rem;
                    }
                }
            }
            
        }
    }
    
}

    
.popup{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0 , 0, 0.5);
    z-index: 190000;
    
    .checkout-popup{
        font-size: 85%;
        position: fixed;
        width: 400px;
        // height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        background: white;
        padding: 3rem;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
        // background: rgb(37, 63, 80);

        .close-btn{
            position: absolute;
            top: 0;
            left: 0;
            padding: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .3s ease;

            &:hover{
                transform: scale(.99)
            }

            .button{
                text-decoration: none;
                color: #333;
                background: none;
                border: none;
                font-weight: 500;
            }
        }

        .checkout-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: inherit;
                align-items: inherit;
                gap: 1.5rem;
                text-align: center;
                

                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.75;
                    width: 90%;

                    span{
                        font-weight: 600;
                    }
                }

                .buttons{
                    // margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    a{
                        width: 100%;
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .1s ease;
                        background: #eee;
                        color: #333;

                        &:hover{
                            transform: scale(.97)
                        }

                        &:nth-child(2){
                            border:1px solid #888;
                            background: none;
                        }
                    }
                }

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
              }
              
              .checkbox-wrapper .background {
                fill:#228B22;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .stroke {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 2px;
                stroke-dashoffset: 100;
                stroke-dasharray: 100;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .check {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
                stroke-dashoffset: 22;
                stroke-dasharray: 22;
                animation: dash 2s ease infinite;
              }
              
              @keyframes dash {
                0% {
                  stroke-dashoffset: 22;
                }
                50% {
                  stroke-dashoffset: 0;
                }
                100% {
                  stroke-dashoffset: 22;
                }
              }
              
              .checkbox-wrapper input[type=checkbox] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
                appearance: none;
                -webkit-appearance: none;
              }
              
              .checkbox-wrapper input[type=checkbox]:hover {
                cursor: pointer;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .background {
                fill: #228B22;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
                stroke-dashoffset: 0;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .check {
                stroke-dashoffset: 0;
                animation: none;
              }
              
        }
    }

    .spinner {
        position: absolute;
        width: 9px;
        height: 9px;
      }
      
      .spinner div {
        position: absolute;
        width: 50%;
        height: 150%;
        background: white;
        transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
        animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
      }
      
      .spinner div:nth-child(1) {
        --delay: 0.1;
        --rotation: 36;
        --translation: 150;
      }
      
      .spinner div:nth-child(2) {
        --delay: 0.2;
        --rotation: 72;
        --translation: 150;
      }
      
      .spinner div:nth-child(3) {
        --delay: 0.3;
        --rotation: 108;
        --translation: 150;
      }
      
      .spinner div:nth-child(4) {
        --delay: 0.4;
        --rotation: 144;
        --translation: 150;
      }
      
      .spinner div:nth-child(5) {
        --delay: 0.5;
        --rotation: 180;
        --translation: 150;
      }
      
      .spinner div:nth-child(6) {
        --delay: 0.6;
        --rotation: 216;
        --translation: 150;
      }
      
      .spinner div:nth-child(7) {
        --delay: 0.7;
        --rotation: 252;
        --translation: 150;
      }
      
      .spinner div:nth-child(8) {
        --delay: 0.8;
        --rotation: 288;
        --translation: 150;
      }
      
      .spinner div:nth-child(9) {
        --delay: 0.9;
        --rotation: 324;
        --translation: 150;
      }
      
      .spinner div:nth-child(10) {
        --delay: 1;
        --rotation: 360;
        --translation: 150;
      }
      
      @keyframes spinner-fzua35 {
        0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
          transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
        }
      
        50% {
          transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
        }
      }
}






@media screen and (max-width: 1080px) {
.masterclass{


    .masterclass-landing{
        background-position: center 1%;


        h1{
            padding: 2rem 2rem;
            padding-top: 7rem;
        }
    }

    .masterclass-enroll{
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        align-items: normal;
        gap: 2.5rem;

        .enroll-left{

            .class-cta{
                font-size: 14px;
            }
        }
    }

    .masterclass-details{

        .m-learn{
            flex-direction: column;
        }
    }


    .masterclass-benefits{

        .benefits-container{
            grid-template-columns: repeat(2, 1fr);
        }
    }

 
}

}

@media screen and (max-width: 720px) {
.masterclass{

    .masterclass-structure{

        .structure-container{
       
                grid-template-columns: repeat(1, 1fr);

                .structure-left{
                    height: 30rem;
                    margin-bottom: 2.5rem;

                    .big-img{
                        background-position: 25% ;
                    }
                }

                .structure-right{

                    .structure-box{
                        align-items: normal;

                        .structure-icon{
                            margin-top: 1%;
                        }
                    }
                }
        }
    }

    .masterclass-benefits{

        .benefits-container{

            display: flex;
            flex-direction: column;
        }
    }

    .next-step{

        p{
            width: 100%;
        }
    }

    .form-page{
        display: flex;
        flex-direction: column;

        .faqs{
            padding: 4rem 2rem;
        }
    }
}
}

@media screen and (max-width: 632px) {

    .popup.pop2{
        background: white !important;

        .checkout-popup{
            width: 100%;
            height: 100%;
            background: white;
        }
    }
}