.adminPost{

    h2{
        margin: 1.4rem 0;
    }

    .post-container{
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;
        gap: 1.5rem;
        background: #f2f2f2;
        padding: 3rem;
        border-radius: 20px;
        transition: .2s ease-in-out;
        margin-bottom: 3rem;

        input{
            padding: .8em;
            border-radius: 5px;
            border: none;
            outline: none;
        }

        input[type="file"] {
            background-color: #f0f0f0;
            border: 1px solid #ccc; 
            padding: 5px; 
            font-size: 16px; 
            cursor: pointer; 
          }

          select{
            border: none;
            outline: none;
            padding: .8em;
            border-radius: 5px;

          }

          span{
            display: flex;
            align-items: center;
            gap: 1rem;
            flex-wrap: wrap;

              button{
                  border: none;
                  background: black;
                  width: 150px;
                  color: white;
                  padding: 10px 15px;
                  border-radius: 8px;
                  cursor: pointer;
                  transition: .3s ease-in-out;
                  
                  &:hover{
                      transform: scale(.85);
                    }
                }
                
                .error-message{
                    color: red;
                    font-weight: 500;
                }
        }
    }

    .success-message{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        background: #f2f2f2;
        width: 70%;
        border-radius: 20px;
        padding: 1rem;

        .close{
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .icon{
                padding: 1rem;
                font-size: 25px;
                transition: .2s ease;
                cursor: pointer;

                &:hover{
                    transform: scale(.95);
                }
            }
        }
        

        .success-icon{
            font-size: 15rem;
            color: hsl(0, 0%, 40%);
        }

        .msg{
            display: flex;
            gap: .3rem;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;

            font-weight: 500;
        }

        a{
            font-weight: 600;
            text-decoration: none;
            background: #BBA58F;
            color: white;
            padding: 10px 15px;
            border-radius: 5px;
            transition: .3s ease-in-out;
            margin-bottom: 3rem;

            &:hover{
                transform: scale(.9);
            }
        }
    }

}