.notification-page{
  width: 100%;
  .popup{
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0 , 0, 0.5);
      z-index: 10000;

      .spinner {
          position: absolute;
          width: 9px;
          height: 9px;
        }
        
        .spinner div {
          position: absolute;
          width: 50%;
          height: 150%;
          background: white;
          transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
          animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
        }
        
        .spinner div:nth-child(1) {
          --delay: 0.1;
          --rotation: 36;
          --translation: 150;
        }
        
        .spinner div:nth-child(2) {
          --delay: 0.2;
          --rotation: 72;
          --translation: 150;
        }
        
        .spinner div:nth-child(3) {
          --delay: 0.3;
          --rotation: 108;
          --translation: 150;
        }
        
        .spinner div:nth-child(4) {
          --delay: 0.4;
          --rotation: 144;
          --translation: 150;
        }
        
        .spinner div:nth-child(5) {
          --delay: 0.5;
          --rotation: 180;
          --translation: 150;
        }
        
        .spinner div:nth-child(6) {
          --delay: 0.6;
          --rotation: 216;
          --translation: 150;
        }
        
        .spinner div:nth-child(7) {
          --delay: 0.7;
          --rotation: 252;
          --translation: 150;
        }
        
        .spinner div:nth-child(8) {
          --delay: 0.8;
          --rotation: 288;
          --translation: 150;
        }
        
        .spinner div:nth-child(9) {
          --delay: 0.9;
          --rotation: 324;
          --translation: 150;
        }
        
        .spinner div:nth-child(10) {
          --delay: 1;
          --rotation: 360;
          --translation: 150;
        }
        
        @keyframes spinner-fzua35 {
          0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
          }
        
          50% {
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
          }
        }
  }
  
  .notification-container{
      width: 90vw;
      margin: 0 auto;
      

      .loading-message{
          margin-block: 3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;
  
          .loading-card{
              width: 90%;
              display: flex;
              flex-direction: column;
              padding: 1rem;
              border-radius: 10px;
              gap: 1rem;
              box-shadow: rgba(80, 78, 78, 0.16) 0px 1px 4px;
  
  
              .loading-img{
                  height: 20px;
                  width: 100%;
                  border-radius: 10px;
              }
  
              .loading-text{
                  height: 20px;
                  width: 80%;
                  border-radius: 10px;
              }
  
              .loading-text-II{
                  height: 20px;
                  width: 60%;
                  border-radius: 10px;
              }
              .loading-text-III{
                  height: 20px;
                  width: 70%;
                  border-radius: 10px;
              }
  
              .loading-img,
              .loading-text,
              .loading-text-II,
                  .loading-text-III{
                  background-color: #ededed;
                  background: linear-gradient(
                      100deg,
                      rgba(255,255,255,0) 40%,
                      rgba(255,255,255, .5) 50%,
                      rgba(255,255,255,0) 60%
                  ) #ededed;
                  background-size: 200% 100%;
                  background-position-x: 100%;
                  animation: 1s loading ease-in-out infinite;
              }
              @keyframes loading {
                  to{
                       background-position-x: -20%;
                  }
              }
  
          }
      }

      h1{
          border-left: 2px solid #bba58f;
          padding-left: .5rem;
          letter-spacing: 1px;
          margin-bottom: 3rem;
          font-size: 1em;

      }

     

      .notifications{
          margin-top: .5rem;
          // margin-bottom: 1rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          
          .notification{
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
              border: 1px solid hsl(0, 0%, 93%);
              display: flex;
              align-items: flex-start;
              gap: 1rem;
              padding: 1rem;
              border-radius: 10px;

              .left{
                  .icon{
                      font-size: 25px;
                  }
              }

              .right{
                  line-height: 1.7;
                  width: 100%;

                  p{
                      font-size: 14px;
                  }

                  .date{
                      font-weight: 400;
                      padding-top: .5rem;
                  }

                  span{
                      margin-top: 1rem;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      button{
                          border: none;
                          padding: 10px 15px;
                          border-radius: 5px;
                          cursor: pointer;
                          transition: .3s ease;

                          &:hover{
                              transform: scale(.95);
                              background: #FF7E00;
                              color: white;
                              font-weight: 500;
                          }
                      }
                  }
              }
          }
      }

      .recent{
          color: #888;
          display: flex;
          align-items: center;
          gap: .5rem;

          span{
              width: 1rem;
              height: 1px;
              background-color: hsl(0, 0%, 73%);
          }
      }

      .recent.older{
        padding-top: 2rem;
      }

      .no-notifications{
        font-weight: 500;
        text-align: center;
        margin-block: 5rem;
        padding-bottom: 4rem;
      }
  }
  
  .page{
      padding-top: 6.5rem;
    }

}

@media screen and (max-width: 595px) {
.notification-page{
.notification-container{

  .notifications{
    font-size: 15px !important;
  }
}
}
}