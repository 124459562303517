.gethelp-page{
    width: 100%;

    .page{
        padding-top: 6.5rem;
      }

    .contact{
        width: 100%;
        padding-top: 3rem;
    
        .contact-header{
            background: rgba(0, 0, 0, 0.5) url('../../stock/contacth.jpg')no-repeat;
            background-size: cover;
            background-position: center 20%;
            background-blend-mode: overlay;
            position: relative;
            
            
            h1{
                padding: 2rem 5rem;
                padding-top: 7rem;
                color: white;
                letter-spacing: 1px;
                
            }
        }
    
        .contact-form{
            width: 90vw;
            margin: 0 auto;
            margin-top: 5rem;
        }
    
        .contact-form{
            display: grid;
            grid-template-columns: 40% 50%;
            justify-content: space-between;
    
            .form-left{
                display: flex;
                flex-direction: column;
                gap: 1rem;
    
                h1{
                    span{
                        color: #bba58f;
    
                    }
                }
    
                p{
                    line-height: 1.5;
                }
    
                .contact-page-cta{
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: #333;
                    background: #F0F0F0;
                    
                        padding: 10px 20px;
                        border-radius: 20px;
                        outline: none;
                        border: none;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        gap: .7rem;
                        transition: .2s ease-in-out;
                
                        &:hover{
                            gap: 1rem;
                        }
        
                        .cta-form{
                            font-size: 20px;
                        }
                    
                }
    
                h6{
                    color: #bba58f;
                }
            }
    
            .form-right{
                padding: 1.5rem;
    
                form{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
    
                    .top{
                        display: flex;
                        gap: 2rem;
                        margin-bottom: 2rem;
    
                        input{
                            padding: 1rem .5rem;
                            border: none;
                            border-bottom: 1px solid #8888;
                            outline: none;
    
                            &:nth-child(2){
                                padding-right: 5rem;
                                // width: 41.5%
                            }
    
    
                        }
    
                    }
    
                    .bottom{
                        margin-bottom: 2rem;
                        width: 80.5%;
                        
    
                        textarea{
                            resize: none;
                            outline: none;
                            border: none;
                            border-bottom: 1px solid #8888;
                            width: 100%;
                        }
    
    
    
                    }
    
                    button{
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        gap: 1rem;
                        padding: 10px 15px;
                        border-radius: 5px;
                        border: none;
                        cursor: pointer;
                        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
                        transition: .3s ease-in-out;
    
                        &:hover{
                            transform: scale(0.9);
                        }
    
                        h3{
                            font-weight: 600;
                        }
    
                        .submit-btn{
                            font-size: 20px;
                        }
                    }
    
                    .form-socials{
                        margin-top: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        gap: .5rem;
                        font-size: 20px;
                      width: 80.5%;
    
                      .icon{
                        cursor: pointer;
                        transition: .3s ease-in-out;
    
    
                        &:hover{
                            transform: scale(0.9);
                        }
                      }
                    }
    
    
                }
            }
        }
    
        .Faqs{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-touch-callout: none;
            margin:0 auto;
            margin-top:4rem;
            width: 90%;
        
            h1{
                font-weight: 500;
            }
          
            .acc-wrap{
                padding: 30px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
        
                input{
                    position: absolute;
                    opacity: 0;
                    z-index: -1;
                }
        
                .accordion{
                    width: 90%;
                    display:grid;
                    grid-template-columns: 50% 50%;
                    gap: 4rem;
        
        
                }
        
                .item{
                    margin-bottom: 20px;
                    border-radius: 8px;
                    overflow: hidden;
                    padding-top: 2rem;
        
                }
        
                .title{
                    background: white;
                    display: flex;
                    padding: 1rem;
                    justify-content: space-between;
                    color: #252525;
                    font-weight: 600;
                    cursor: pointer;
        
                    &::after{
                        content: "\002B";
                        font-size: 20px;
                        width: 1rem;
                        height: 1rem;
                        text-align: center;
                        transition: .3s ease-in-out;
                    }
                }
        
                .acc-content{
                    background: white;
                    padding: 0 1rem;
                    color: #828282;
                    line-height: 2;
                    max-height: 0;
                    transition: .3s ease-in-out;
                    border-bottom: 1px solid #200b0b;
        
                }
        
                input:checked + .title:after{
                    transform: rotate(90deg);
                }
        
                input:checked + label + .acc-content{
                    max-height: 100vh;
                    padding: 1rem;
                }
            }
           
              
        }
    
    }
    
    @media screen and (max-width: 1080px) {
    .contact{
    
        .contact-header{
    
            background-position: center 1%;
            h1{
                padding: 2rem 2rem;
                padding-top: 7rem;
            }
        }
    
        .contact-form{
            margin-top: 3rem;
            display: flex;
            flex-direction: column;
    
            .form-left{
                width: 85vw;
                margin: 0 auto;
                margin-bottom: 2rem;
            }
    
            .form-right{
                form{
                    .top{
                        flex-direction: column;
                    }
    
                    .bottom,
                    .form-socials{
                        width: 100%;
                    }
    
                }
            }
        }
    
    
        .Faqs{
    
            h1{
        
                text-align: center;
            }
        
           .acc-wrap .accordion{
                display:flex;
                flex-direction: column;
                gap: 0;
            }
        }
    }
    }
}