.cart-page{
    width: 100%;

    .popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0 , 0, 0.5);
        z-index: 10000;

        .spinner {
            position: absolute;
            width: 9px;
            height: 9px;
          }
          
          .spinner div {
            position: absolute;
            width: 50%;
            height: 150%;
            background: white;
            transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;
          }
          
          .spinner div:nth-child(1) {
            --delay: 0.1;
            --rotation: 36;
            --translation: 150;
          }
          
          .spinner div:nth-child(2) {
            --delay: 0.2;
            --rotation: 72;
            --translation: 150;
          }
          
          .spinner div:nth-child(3) {
            --delay: 0.3;
            --rotation: 108;
            --translation: 150;
          }
          
          .spinner div:nth-child(4) {
            --delay: 0.4;
            --rotation: 144;
            --translation: 150;
          }
          
          .spinner div:nth-child(5) {
            --delay: 0.5;
            --rotation: 180;
            --translation: 150;
          }
          
          .spinner div:nth-child(6) {
            --delay: 0.6;
            --rotation: 216;
            --translation: 150;
          }
          
          .spinner div:nth-child(7) {
            --delay: 0.7;
            --rotation: 252;
            --translation: 150;
          }
          
          .spinner div:nth-child(8) {
            --delay: 0.8;
            --rotation: 288;
            --translation: 150;
          }
          
          .spinner div:nth-child(9) {
            --delay: 0.9;
            --rotation: 324;
            --translation: 150;
          }
          
          .spinner div:nth-child(10) {
            --delay: 1;
            --rotation: 360;
            --translation: 150;
          }
          
          @keyframes spinner-fzua35 {
            0%, 10%, 20%, 30%, 50%, 60%, 70%, 80%, 90%, 100% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
            }
          
            50% {
              transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
            }
          }
    }

    .checkout-popup{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        background: white;
        // background: rgb(37, 63, 80);

        .close-btn{
            position: absolute;
            top: 0;
            left: 0;
            padding: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: .3s ease;

            &:hover{
                transform: scale(.9)
            }

            .button{
                text-decoration: none;
                color: #333;
                background: none;
                border: none;
                font-weight: 500;
            }
        }

        .checkout-container{
                display: inherit;
                flex-direction: inherit;
                justify-content: inherit;
                align-items: inherit;
                gap: 1.5rem;
                text-align: center;
                

                p{
                    font-weight: 500;
                    color: #333;
                    line-height: 1.5;

                    span{
                        font-weight: 600;
                    }
                }

                .buttons{
                    // margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    a{
                        width: 100%;
                        padding: 15px 8px;
                        border-radius: 25px;
                        border: none;
                        cursor: pointer;
                        width: 250px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: .3s ease;
                        background: #eee;
                        color: #333;

                        &:hover{
                            transform: scale(.9)
                        }

                        &:nth-child(2){
                            border:1px solid #888;
                            background: none;
                        }
                    }
                }

            .checkbox-wrapper {
                position: relative;
                display: inline-block;
                width: 90px;
                height: 90px;
              }
              
              .checkbox-wrapper .background {
                fill:#228B22;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .stroke {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 2px;
                stroke-dashoffset: 100;
                stroke-dasharray: 100;
                transition: ease all 0.6s;
                -webkit-transition: ease all 0.6s;
              }
              
              .checkbox-wrapper .check {
                fill: none;
                stroke: #fff;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 3px;
                stroke-dashoffset: 22;
                stroke-dasharray: 22;
                animation: dash 2s ease infinite;
              }
              
              @keyframes dash {
                0% {
                  stroke-dashoffset: 22;
                }
                50% {
                  stroke-dashoffset: 0;
                }
                100% {
                  stroke-dashoffset: 22;
                }
              }
              
              .checkbox-wrapper input[type=checkbox] {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                margin: 0;
                opacity: 0;
                appearance: none;
                -webkit-appearance: none;
              }
              
              .checkbox-wrapper input[type=checkbox]:hover {
                cursor: pointer;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .background {
                fill: #228B22;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .stroke {
                stroke-dashoffset: 0;
              }
              
              .checkbox-wrapper input[type=checkbox]:checked + svg .check {
                stroke-dashoffset: 0;
                animation: none;
              }
              
        }
    }

    .cart-container{
    width: 90vw;
    margin: 0 auto;

    h1{
        border-left: 2px solid #bba58f;
        padding-left: .5rem;
        letter-spacing: 1px;
        margin-bottom: 3rem;
        font-size: 1em;

    }

    .empty-cart{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-block: 6rem;

        .cart-icon{
            font-size: 50px;
        }

        a{
            text-decoration: none;
            color: #333;
            background: #bba58f;
            padding: 15px 17.5px;
            border-radius: 5px;
            color: white;
            font-weight: 700;
            transition: .2s ease-in-out;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            &:hover{
                transform: scale(.9);
            }
        }

        p{
            text-align: center;
            font-weight: 500;
        }
    }
    .loading-message{
        margin-block: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 2rem;

        .loading-card{
            width: 90%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            border-radius: 10px;
            gap: 1rem;
            box-shadow: rgba(80, 78, 78, 0.16) 0px 1px 4px;


            .loading-img{
                height: 20px;
                width: 100%;
                border-radius: 10px;
            }

            .loading-text{
                height: 20px;
                width: 80%;
                border-radius: 10px;
            }

            .loading-text-II{
                height: 20px;
                width: 60%;
                border-radius: 10px;
            }
            .loading-text-III{
                height: 20px;
                width: 70%;
                border-radius: 10px;
            }

            .loading-img,
            .loading-text,
            .loading-text-II,
                .loading-text-III{
                background-color: #ededed;
                background: linear-gradient(
                    100deg,
                    rgba(255,255,255,0) 40%,
                    rgba(255,255,255, .5) 50%,
                    rgba(255,255,255,0) 60%
                ) #ededed;
                background-size: 200% 100%;
                background-position-x: 100%;
                animation: 1s loading ease-in-out infinite;
            }
            @keyframes loading {
                to{
                     background-position-x: -20%;
                }
            }

        }
    }

    .cart{
        position: relative;
        margin-block: 3rem;
        width: 100%;
        display: grid;
        grid-template-columns: 65% 30%;
        gap: 4rem;
        margin: 0 auto;
        align-items: start;
        margin-bottom: 10rem;
        // border: 1px solid red;
        
        .main-container{
            position: relative;
            padding: 1.5rem;
            padding-bottom: 0;
            width: 100%;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        }

        .cart-item{
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          padding-block: 1.5rem;
          border-bottom: 1px solid hsl(0, 0%, 90%);


            .product-info{
                display: flex;
                gap: 2rem;
                width: 100%;
                justify-content: space-between;
            

                .info{
                    display: flex;
                    gap: 2rem;

                    .mobile{
                        display: none;
                        font-weight: 600;
                        font-size: 18px;
                    }

                    img{
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                        border-radius: 8px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    }

                    .name-desc{
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        p{
                            line-height: 1.5;
                        }
                    }
                }

                .price{
                    display: flex;
                    justify-content: flex-end;
                    
                    p{
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
                
            }

            .cart-control{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;

                .delete-btn{
                    display: inherit;
                    align-items: center;
                    gap: 5px;
                    background: hsl(17, 21%, 96%);
                    border-radius: 5px;
                    padding: 5px 12px;
                    border: none;
                    cursor: pointer;
                    font-weight: 500;
                    transition: .3s ease-in-out;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

                    &:hover{
                        transform: scale(.95);
                    }

                    .delete-icon{
                        font-size: 20px;
                    }
                }

                .quantity-counter{
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    button{
                        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                            background: hsl(17, 21%, 96%);
                            border-radius: 5px;
                            border: none;
                            transition: .3s ease-in-out;


                            &:hover{
                                transform: scale(.95);
                            }
                        
                    }

                    .count-icon{
                        padding: 5px 8px;
                        border: none;
                        cursor: pointer;
                    }
                }
            }
        }

      

         .cart-summary{
        position: sticky;
         top: 5em;
        // max-width:300px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 1rem;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: white;

        h3{
          border-bottom: 1px solid hsl(0, 0%, 90%);
        padding-block: 1rem;
        }

        p{
            display: flex;
            align-items: center;
            justify-content: space-between;
            
        }

        .address{

            h6{
                display: flex;
                align-items: center;
                justify-content: space-between;
                  border-bottom: 1px solid hsl(0, 0%, 90%);
                padding-block: 1rem;


                a{
                    text-decoration: none;
                    color: #bba58f;
                }
            }

            p{
                margin-top: 1rem;
                background: #ededed;
                font-size: 13px;
                padding: .5rem;
                border-radius: 5px;
                font-weight: 500;
            }
        }

        .delivery{

            h6{
                display: flex;
                align-items: center;
                justify-content: space-between;
                  border-bottom: 1px solid hsl(0, 0%, 90%);
                padding-block: 1rem;
            }

        p{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            margin-top: 1rem;

            a{
                color: #bba58f;
                text-decoration: none;
                font-weight: 600;
            }
        }

        .estimate{
            p{
                display: block;
                span{
                    font-weight: 600;
                }
            }
        }

        .shipping-state{
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin-block: .5rem;

            .state{
                display: flex;
                align-items: center;
                font-size: 14px;
                gap: .2rem;
            }
        }


        }

        .total{
            p{
                font-weight: 600;
            }
            li{
                list-style: none;
                font-size: 12px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
        }

        button{
            margin-block: .5rem;
            padding-block: .5rem;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            transition: .2s ease;
             background: #bba58f;
             color: white;
             font-weight: 600;


            &:hover{
            transform: scale(.95);
            background: #888;
            }

        }
        }
    }
    } 
    
.page{
    padding-top: 6.5rem;
  }
}

@media screen and (max-width: 1035px) {
   .cart-page{

       .cart-container{

           .loading-message{
               display: grid;
               grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
           gap: .75rem;
           justify-content: center;
           
           .loading-card{
               font-size: 0.8em;
               width: 11em;
               
            }
        }
        
    }
        
    }

    }

@media screen and (max-width: 999px) {
.cart-page{

    .cart-container{
        width: 90%;
        margin: 0 auto;

        .cart{
            display: flex;
            flex-direction: column;
            width: 90vw;
            
            .main-container{
                padding: 1rem;
            padding-bottom: 0;
            }

            .cart-container{
                width: 90%;
            }

            .cart-summary{
                width: 90%;
                box-shadow: none;
                margin: 0 auto;
            }
        }

       
    }

    .main-container{
        font-size: .9em;
        overflow: hidden;
        padding-inline: 0;
    }

 
}
}

@media screen and (max-width: 550px) {
    .cart-page{
    
        .cart-container{
            width: 90%;
            margin: 0 auto;
    
            .cart .cart-item .product-info{
    
                .price{
                    display: none;
                }

                .info .mobile{
                    display: block;
                }

            }

        }
     
    }
    }