@import url('./Login.scss');
@import url('./SignUp.scss');
@import url('./UserNav.scss');
@import url('./UserDashboard.scss');
@import url('./Cart.scss');
@import url('./Store.scss');
@import url('./Onboarding.scss');
@import url('./ProfilePic.scss');
@import url('./Address.scss');
@import url('./UserProfile.scss');
@import url('./Notifications.scss');
@import url('./Myorders.scss');
@import url('./Reset.scss');
@import url('./GetHelp.scss');
@import url('./UserMasterclass.scss');

.appContainer{
  margin: 0 auto;
  width: 90vw;
}



.spinner-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.loginRight{
        background: url('../../stock/login.jpg')no-repeat;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        position: relative;
        border-radius:20px;
        border-bottom-left-radius: 8rem;
    }

    .load-spinner{
        animation: spin 2s linear infinite;
        padding: 1px 15px;
    }

    @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
