.about{
    width: 100%;

    .about-header{
        background: rgba(0, 0, 0, 0.5) url('../../stock/abouth.jpg')no-repeat;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        position: relative;

        h1{
            padding: 2rem 5rem;
            padding-top: 7rem;
            color: white;
            letter-spacing: 1px;
            
        }
    }

    .about-story,
    .about-values,
    .about-unique,
    .about-touch{
    width: 90vw;
    margin: 0 auto;
    margin-top: 5rem;
    }

    .about-story{
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: space-between;
        align-items: center;
        gap: 5rem;
        
        .abt-story-left{
            display: flex;
            flex-direction: column;
            gap: 1rem;

            h3{

                span{
                    color: #bba58f;
                }
            }

            p{
            line-height: 1.7;
            }

            .about-page-cta{
                width: fit-content;
                display: flex;
                align-items: center;
                text-decoration: none;
                color: #333;
                background: #F0F0F0;
                
                    padding: 10px 20px;
                    border-radius: 20px;
                    outline: none;
                    border: none;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    gap: .7rem;
                    transition: .2s ease-in-out;
            
                    &:hover{
                        gap: 1rem;
                    }

                    .cta-insta{
                        font-size: 20px;
                    }
                
            }
        }

        .abt-story-right{
            width: 90%;
            position: relative;

            img{
                width: 80%;
            }
        }
    }

    .about-values{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .values-header{
            text-align: center;
            width: 50%;
            display: inherit;
            flex-direction: inherit;
            gap: .4rem;
            margin-bottom: 2.5rem;

            h1{
                span{
                    color: #bba58f;
                }
            }

            p{
                line-height: 1.5;
            }
        }

        .value-container{
            width: 80%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;


            .levalues{
                position: relative;
                display: flex;
                flex-direction: column;
                border-radius: 20px;
                padding: .7rem 0 ;

                &:nth-child(1){
                    background:#F5F2ED ;
                }
                &:nth-child(2){
                    background:#E9F3ED ;
                }
                &:nth-child(3){
                    background:#EEEDF3 ;
                }
                &:nth-child(4){
                    background: #F5ECF1;
                }



                .value-icon{
                    position: absolute;
                    margin-top: 3rem;
                    margin-left: -1rem;
                    font-size: 25px;
                    background: #333;
                    border-radius: 50%;
                    color: white;
                    padding: .5rem;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                }
                .first{
                    background: #ECBE07;
                }
                .second{
                    background: #00CA5B;
                }
                .third{
                    background: #836AED;
                }
                .fourth{
                    background: #EF4D92;
                }

                h3{
                    margin-left: 2rem;
                    padding: 0 1rem;
                    padding-top: .75rem;
                }
                
                p{
                    padding: .9rem;
                    margin-left: 2rem;
                    line-height: 1.5;
                    width: 80%;
                }


            }
        }
    }

    .about-unique{
        display: flex;
        gap: 2rem;

        h1{
            width: 300px;

            span{
                color: #bba58f;
            }
        }

        .abt-unique-container{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;

            .leunique{

                span{
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    margin-bottom: .5rem;

                    .unique-icon{
                        font-size: 25px;
                    }
                }

                p{
                    line-height: 1.5;
                }
            }
        }
    }

    .about-touch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .8rem;
        margin-bottom: 4rem;


        .about-page-cta{
            width: fit-content;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #333;
            background: #F0F0F0;
            
                padding: 10px 20px;
                border-radius: 20px;
                outline: none;
                border: none;
                font-weight: 600;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: .7rem;
                transition: .2s ease-in-out;
        
                &:hover{
                    gap: 1rem;
                }

                .cta-insta{
                    font-size: 20px;
                }
            
        }
    }

    
}

@media screen and (max-width: 1080px) {
.about{
    width: 100vw;

    .about-header{
        h1{
            padding: 2rem 2rem;
            padding-top: 7rem;
        }
    }

    .about-story{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-top: 3rem;

        .abt-story-right{
            img{
                width: 100%;
                margin: 0 auto;
            }
        }
    }

    .about-values{

        .values-header{
            width: 100%;
        }

        .value-container{
            display: flex;
            flex-direction: column;
            width: 90%;
        }

    }

    .about-unique{
        flex-direction: column;

        .abt-unique-container{
            display: flex;
            flex-direction: column;
        }
    }
}
}