.adminHome{

    .adminlog{

        .body{
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1.6rem;
            text-align: center;
            margin-block: 9rem;
            


            button{
                width: fit-content;
                background: black;
                color: white;
                border: none;
                padding: 10px 18px;
                border-radius: 5px;
                cursor: pointer;
                transition: .2s ease-in-out;
                grid-column: span 2; /* Span the button across both columns */
                place-self: center;
                width: 250px;
    
                &:hover{
                    transform: scale(.95);
                }
                .login-spinner{
                    animation: spin 2s linear infinite;
                    width: 3.5rem;
                    transition: .2s ease-in-out;
    
                }
    
                @keyframes spin {
                    from {
                      transform: rotate(0deg);
                    }
                    to {
                      transform: rotate(360deg);
                    }
                  }
            }


        }
    }
}